<template>
  <div>
    <v-menu
      v-model="open"
      :right="true"
      offset-y
      content-class="notifications-nav"
    >
      <template v-slot:activator="{ on }">
        <v-badge color="green" overlap>
          <template v-slot:badge>
            <span v-if="count > 0">{{ count }}</span>
          </template>
          <v-btn :text="true" v-on="on" class="inactive">
            <v-icon large>mdi-bell</v-icon>
            <div class="button-text">{{ $t('navbar.notifacations') }}</div>
          </v-btn>
        </v-badge>
      </template>

      <no-ssr>
        <v-list ref="list" @scroll.native="scroll" class="notifications">
          <v-list-item v-if="notifications.length === 0">
            <v-list-item-title>{{
              $t('notifications.empty')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(notification, i) in notifications"
            :key="i"
            :class="{ new: !notification.wasRead }"
            @click="openOffer(notification.offerId)"
            class="notification"
          >
            <v-list-item-title>
              {{ notification.notificationMessage }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </no-ssr>
    </v-menu>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'

export default {
  components: {
    'no-ssr': NoSSR
  },
  data() {
    return {
      loadMore: true,
      loading: false,
      open: false,
      page: 1,
      pageSize: 5,
      showOfferModal: false,
      offer: {},
      notifications: [],
      count: 0
    }
  },
  watch: {
    open(val) {
      if (val) {
        this.page = 1
        this.notifications = []
        this.loadMore = true
        this.loading = false
        this.getNotifications()
      }
    }
  },
  mounted() {
    this.startNotificationPolling()
    this.getNotificationsCount()
  },
  methods: {
    startNotificationPolling() {
      setInterval(() => {
        this.getNotificationsCount()
      }, 10000)
    },
    async getNotificationsCount() {
      const count = await this.$notificationService.getNotificationsCount()
      this.count = count
    },
    async getNotifications() {
      const params = {
        page: this.page++,
        limit: this.pageSize
      }

      const request = await this.$notificationService.getNotifications(params)

      if (request.data.items.length > 0) {
        this.notifications = this.notifications.concat(request.data.items)

        // Stop scroll-loader
        if (request.data.items.length < this.pageSize) {
          this.loadMore = false
        }
      } else {
        this.loadMore = false
      }
    },
    async openOffer(offerId) {
      let offer = Object
      try {
        offer = await this.$offerService.getOffer(offerId)
      } catch {
        this.$toast.error(this.$t('toaster.offerNotExist'))
        return
      }

      this.$bus.$emit('open-offer', offer.data)
    },
    async scroll() {
      if (!this.loading && this.loadMore) {
        const container = this.$refs.list.$el
        const paddings = 16
        const offsetHeight = this.notifications.length * 80 + paddings - 40

        const bottomOfWindow = container.scrollTop + 400 > offsetHeight

        //  console.log(container.scrollTop, offsetHeight, bottomOfWindow)

        if (bottomOfWindow) {
          this.loading = true
          await this.getNotifications()
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button-text {
  @include breakpoint(xs) {
    display: none;
  }
}

.v-btn {
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-transform: none !important;

  @include breakpoint(xs) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    min-width: 40px;
  }
}
.inactive {
  color: #210759;
}

.notifications {
  max-height: 400px;
  width: 320px;
  overflow-y: scroll;
}
.notification {
  white-space: normal !important;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  border-bottom: 1px solid #dccdff;
  color: #210759;
  height: 79px;
}

.notification.new {
  background: #e9e5f8;
}

.notification > .v-list-item__title {
  white-space: normal !important;
  line-height: 22px;
}

.v-list {
  border-radius: 0px 0px 8px 8px;
  padding-top: 0;
  width: 100%;
}
.v-menu__content {
  border-radius: 0px 0px 8px 8px;
  width: 320px;
  max-width: 320px;

  @include breakpoint(xs) {
    margin-top: 10px;
  }
}
</style>
