<template>
  <div>
    <HomeNavigationDrawer
      :rightLayout="rightLayout"
      :drawer="drawer"
      @update="drawerUpdate"
      @open-login="showLoginModal = true"
    />
    <v-app-bar
      :clipped-right="rightLayout"
      :clipped-left="!rightLayout"
      :height="smallScreen ? 100 : 132"
      class="site-header"
      color="white"
      elevation="0"
      fixed
      app
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        x-large
        color="#864DFF"
        class="expand-icon"
      />
      <div @click="goHome" class="logo-container">
        <div class="logo" />
      </div>
      <div class="flex-grow-1"></div>
      <v-btn
        v-if="!$auth.loggedIn"
        :text="true"
        @click.stop="showLoginModal = true"
        class="px-0 login-button"
      >
        <div class="button-text purple-text pr-2">{{ $t('login') }}</div>
        <v-icon color="#864DFF" large>mdi-location-enter</v-icon>
      </v-btn>
      <v-btn
        v-else
        :text="true"
        :to="localePath({ path: '/dashboard' })"
        class="px-0 login-button"
      >
        <div class="button-text purple-text pr-2">{{ $t('navbar.home') }}</div>
        <v-icon color="#864DFF" large>mdi-location-enter</v-icon>
      </v-btn>
    </v-app-bar>
    <LoginModal v-model="showLoginModal" />
    <ForgetPassword v-model="showForgetModal" />
  </div>
</template>

<script>
import LoginModal from '~/components/modals/LoginModal'
import ForgetPassword from '~/components/modals/ForgetPasswordModal'
import HomeNavigationDrawer from '~/components/navdrawers/HomeNavigationDrawer'

export default {
  components: {
    LoginModal,
    ForgetPassword,
    HomeNavigationDrawer
  },
  data() {
    return {
      drawer: false,
      showLoginModal: false,
      showForgetModal: false,
      windowWidth: 0
    }
  },
  computed: {
    rightLayout() {
      return this.$i18n.locale === 'he'
    },
    smallScreen() {
      return this.windowWidth <= 700
    }
  },
  mounted() {
    this.$bus.$on('forget-password', () => {
      this.showForgetModal = true
    })

    this.windowWidth = window.innerWidth
  },
  methods: {
    drawerUpdate(drawer) {
      this.drawer = drawer
    },
    goHome() {
      this.$router.push(this.localePath({ path: '/' }))
    }
  }
}
</script>

<style lang="scss" scoped>
.v-sheet::v-deep .v-toolbar__content {
  padding: 0px 60px 0px 60px;
  @include breakpoint(xs) {
    padding: 0px 20px 0px 20px;
    height: 100px !important;
  }
}

header.v-app-bar {
  @include breakpoint(xs) {
    height: 100px !important;
  }
}
.expand-icon {
  @include breakpoint(xs) {
    position: absolute;
    position: absolute;
    right: 15px;
  }
}
.mdi-menu {
  font-size: 42px !important;
}

.v-toolbar__title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  border: 2px solid #f4f3f7;
  box-sizing: border-box;
  border-radius: 4px;

  padding-right: 21px;

  @include breakpoint(xs) {
    font-size: 14px;
    line-height: 17px;
  }
}
.v-btn {
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-transform: none !important;

  @include breakpoint(xs) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    min-width: 40px;
  }
}
.inactive {
  color: #210759;
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 43px;
  width: 275px;
  background-image: url('/logo.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.login-button {
  @include breakpoint(xs) {
    display: none;
  }
}
.button-text {
  font-family: 'Biennale SemiBold';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.13px;
  line-height: 20px;
  text-align: right;

  @include breakpoint(xs) {
    display: none;
  }
}

.purple-text {
  color: #864dff;
}

.rtl-layout {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child,
  .v-toolbar__extension > .v-btn.v-btn--icon:first-child {
    margin-left: 0px;
    @include breakpoint(xs) {
      margin-right: 0px;
    }
  }
  .v-btn--fab {
    @include breakpoint(xs) {
      left: 0%;
      margin-left: 88px;
      margin-bottom: -22px;
    }
  }
  .button-text {
    font-family: 'Almoni';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.14px;
    line-height: 16px;
    text-align: right;
    order: 2;
  }
  .site-header {
    @include breakpoint(xs) {
      direction: ltr;
    }
  }
}
</style>
