import OffserService from '~/services/OfferService'
import UserService from '~/services/UserService'
import NotificationService from '~/services/NotificationService'
import VerificationService from '~/services/VerificationService'
import PaymentService from '~/services/PaymentService'
export default (ctx, inject) => {
  // inject the repository in the context (ctx.app.$repository)
  // And in the Vue instances (this.$repository in your components)
  const offerServiceWithAxios = OffserService(ctx.$axios)
  const userServiceWithAxios = UserService(ctx.$axios)
  const notificationServiceWithAxios = NotificationService(ctx.$axios)
  const verificationServiceWithAxios = VerificationService(ctx.$axios)
  const paymentServiceWithAxios = PaymentService(ctx.$axios)
  inject('offerService', offerServiceWithAxios)
  inject('userService', userServiceWithAxios)
  inject('notificationService', notificationServiceWithAxios)
  inject('verificationService', verificationServiceWithAxios)
  inject('paymentService', paymentServiceWithAxios)
}
