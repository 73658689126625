<template>
  <svg
    width="36"
    height="25"
    viewBox="0 0 36 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99999 8.83333H0.99999C0.57897 8.83333 0.203045 9.09704 0.0597187 9.49291C-0.0836073 9.88878 0.0363691 10.332 0.359806 10.6016L17.3598 24.7682C17.7307 25.0773 18.2693 25.0773 18.6402 24.7682L35.6402 10.6016C35.9636 10.332 36.0836 9.88878 35.9403 9.49291C35.7969 9.09704 35.421 8.83334 35 8.83334H29V0H7.99999V8.83333Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: '#712fff'
    }
  }
}
</script>
