<template>
  <v-dialog
    v-model="show"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    content-class="full-screen"
  >
    <v-card>
      <v-card-title :color="options.color" class="headline">
        <v-icon
          @click.native="cancel"
          size="23"
          color="#712fff"
          class="close-confirmation"
          >mdi-close</v-icon
        >
        {{ $t('paymentModal.title') }}
      </v-card-title>
      <v-card-text class="pa-4">
        {{ $t('paymentModal.description') }}
      </v-card-text>
      <div id="paypal-button-container"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    paymentDetails: null,
    error: null,
    renderedPaypal: false,
    options: {
      color: 'primary',
      width: 480,
      zIndex: 200,
      style: {
        size: 'small'
      }
    }
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.error = null
        this.paymentDetails = null
      }
    }
  },
  methods: {
    checkout() {
      this.loading = true
      this.$refs.checkoutRef.redirectToCheckout()
    },
    open(paymentId, options) {
      this.dialog = true
      this.options = Object.assign(this.options, options)
      const that = this

      if (!this.renderedPaypal) {
        this.renderedPaypal = true
        window.setTimeout(() => {
          // eslint-disable-next-line no-undef
          paypal
            .Buttons({
              createOrder(data, actions) {
                // This function sets up the details of the transaction, including the amount and line item details.
                return paymentId
              },
              onApprove(data, actions) {
                that.paymentDetails = data
                that.send()
              }
            })
            .render('#stripe-checkout')
        }, 0)

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      }
    },
    send() {
      if (this.paymentDetails) {
        this.resolve(this.paymentDetails)
        this.dialog = false
      } else {
        this.cancel()
      }
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__actions {
  padding: 12px;
}

.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.v-card__title {
  background: white;
  color: #712fff;
  padding: 12px 0px 12px 16px !important;
  border-bottom: 1px solid #dccdff;
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 20px;
}
.close-confirmation {
  margin-right: 12px;
  cursor: pointer;
}
.v-card__text {
  padding: 20px 16px 5px 16px !important;
}
.v-btn {
  color: #712fff;
}
.v-card::v-deep .mdi-checkbox-blank-outline {
  color: #712fff;
}
.title-1 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #313131;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
