<template>
  <v-dialog
    v-model="show"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    content-class="full-screen"
  >
    <v-card>
      <Notification v-if="error" :message="error" />
      <v-card-title :color="options.color" class="headline">
        <v-icon
          @click.native="cancel"
          size="23"
          color="#712fff"
          class="close-confirmation"
          >mdi-close</v-icon
        >
        {{ $t('paymentModal.title') }}
      </v-card-title>
      <v-card-text class="pa-4">
        {{ $t('paymentModal.description') }}
      </v-card-text>
      <card
        :class="{ complete }"
        :options="stripeOptions"
        @change="complete = $event.complete"
        :stripe="stripeKey"
        class="stripe-card"
      />
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn @click="ok" color="#712fff" text>{{ $t('ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Card, createToken } from 'vue-stripe-elements-plus'
import Notification from '@/components/Notification'
export default {
  components: {
    Card,
    Notification
  },
  data: () => ({
    dialog: false,
    error: null,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 480,
      zIndex: 200,
      style: {
        size: 'small'
      }
    },
    complete: false,
    stripeOptions: {
      // see https://stripe.com/docs/stripe.js#element-options for details
    },
    offerId: null,
    detail: {},
    stripeKey: process.env.STRIPE_PK,
    user: {}
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.error = null
      }
    }
  },
  methods: {
    open(offerId, detail, user) {
      this.dialog = true
      this.offerId = offerId
      this.detail = detail
      this.user = user
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async ok() {
      try {
        await this.createStripeIntent()
        this.resolve(true)
        this.dialog = false
      } catch (error) {
        this.error = error
      }
    },
    cancel() {
      this.reject('user canceled the payment')
      this.dialog = false
    },
    async createStripeIntent() {
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      try {
        await this.$paymentService.fetchPaymentIntent(this.offerId, this.detail)
        const stripeToken = await createToken()
        if (!this.user.stripeCustomerId) {
          await this.$userService.changeStripeCustomer({
            stripeToken: stripeToken.token.id,
            email: this.user.email
          })
        } else {
          await this.$userService.updateStripeCustomer({
            stripeToken: stripeToken.token.id,
            customerId: this.user.stripeCustomerId
          })
        }
      } catch (e) {
        return Promise.reject(e.response.data.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__actions {
  padding: 12px;
}
.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
.v-card__title {
  background: white;
  color: #712fff;
  padding: 12px 0px 12px 16px !important;
  border-bottom: 1px solid #dccdff;
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 20px;
}
.close-confirmation {
  margin-right: 12px;
  cursor: pointer;
}
.v-card__text {
  padding: 20px 16px 5px 16px !important;
}
.v-btn {
  color: #712fff;
}
.v-card::v-deep .mdi-checkbox-blank-outline {
  color: #712fff;
}
.title-1 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #313131;
}
.stripe-card {
  width: '100%';
  padding: 16px;
  /* border: 1px solid grey; */
}
.stripe-card.complete {
  border-color: green;
}
</style>
