<template>
  <v-dialog
    v-model="show"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title :color="options.color" class="headline">
        <v-icon
          @click.native="cancel"
          size="23"
          color="#712fff"
          class="close-confirmation"
          >mdi-close</v-icon
        >
        {{ $t('selectParty.title') }}
      </v-card-title>
      <v-card-text class="pa-4">
        <Notification v-if="error" :message="error" />
        <div class="title-1 pb-3">{{ $t('selectParty.message') }}</div>
        <User v-model="participant" :title="$t('newOfferModal.emailOrPhone')" />
        <v-checkbox
          v-model="propagateOffer"
          :hide-details="true"
          color="#712FFF"
          class="mt-0"
        >
          <template slot="label">
            <div class="title-1">{{ $t('selectParty.propagateOffer') }}</div>
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="send" class="v-btn" text>
          {{ $t('selectParty.send') }}
        </v-btn>
        <v-btn @click.native="cancel" class="v-btn" text>
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Notification from '@/components/Notification'
import User from '@/components/User'

export default {
  components: {
    Notification,
    User
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    propagateOffer: false,
    participant: {},
    error: null,
    options: {
      color: 'primary',
      width: 350,
      zIndex: 200
    }
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.error = null
        this.participant = {}
        this.propagateOffer = false
      }
    }
  },
  methods: {
    open(options) {
      this.dialog = true
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    send() {
      if (this.participant.isValid) {
        const data = this.participant.isPhone
          ? { phone: this.participant.val }
          : { email: this.participant.val }
        data.propagateOffer = this.propagateOffer

        this.resolve(data)
        this.dialog = false
      } else {
        this.error = this.$t('error.InvalidWith')
      }
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__actions {
  padding: 12px;
}

.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.v-card__title {
  background: white;
  color: #712fff;
  padding: 12px 0px 12px 16px !important;
  border-bottom: 1px solid #dccdff;
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 20px;
}
.close-confirmation {
  margin-right: 12px;
  cursor: pointer;
}
.v-card__text {
  padding: 20px 16px 5px 16px !important;
}
.v-btn {
  color: #712fff;
}
.v-card::v-deep .mdi-checkbox-blank-outline {
  color: #712fff;
}
.title-1 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #313131;
}
</style>
