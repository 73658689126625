<template>
  <v-dialog v-if="show" v-model="show" max-width="303px" content-class="mx-0">
    <OwnOfferModal
      v-if="isInitiator(offer) && !isParticipant(offer)"
      :offer="offer"
      v-on:hide="show = false"
      review
    />
    <IncomingOffer
      v-else
      :offer="offer"
      :isInitiator="isInitiator(offer)"
      v-on:hide="show = false"
    />
  </v-dialog>
</template>

<script>
import IncomingOffer from '@/components/offers/IncomingOffer.vue'
import OwnOffer from '@/components/offers/OwnOffer.vue'

export default {
  components: {
    IncomingOffer,
    OwnOfferModal: OwnOffer
  },
  props: {
    value: Boolean,
    offer: Object
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', !this.value)
    },
    isInitiator(offer) {
      const initiator = offer.parties.find(
        (party) => party.partyRole === 'initiator'
      )
      return (
        initiator.user && initiator.user.username === this.$auth.user.username
      )
    },
    isParticipant(offer) {
      const participant = offer.parties.filter(
        (party) => party.partyRole === 'participant'
      )
      if (participant.length === 2) {
        if (
          participant[0].user.username === this.$auth.user.username ||
          participant[1].user.username === this.$auth.user.username
        ) {
          return true
        }
      }
      return false
    }
  }
}
</script>
