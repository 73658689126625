<template>
  <v-navigation-drawer
    v-model="visible"
    :right="rightLayout"
    fixed
    width="327"
    color="#18004D"
    dark
  >
    <v-icon
      @click="visible = false"
      size="46"
      color="#FAA337"
      style="margin-left: 51px;"
      class="pointer"
      >mdi-close</v-icon
    >
    <v-list class="auth">
      <v-list-item @click="login" class="pl-13">
        <v-list-item-content class="login-text">
          <v-list-item-title v-text="$t('login')" class="text-2" />
        </v-list-item-content>
        <v-list-item-action class="login-icon">
          <v-icon size="32">mdi-location-enter</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/register' })"
        @click="visible = false"
        router
        exact
        class="pl-13"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('register.title')" class="text-2" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list class="mt-12">
      <v-list-item
        :to="localePath({ path: '/about' })"
        @click="visible = false"
        router
        exact
        class="pl-13"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.about')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/contact' })"
        @click="visible = false"
        router
        exact
        class="pl-13"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.contact')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/terms' })"
        @click="visible = false"
        router
        exact
        class="pl-13"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.terms')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/privacy' })"
        @click="visible = false"
        router
        exact
        class="pl-13"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.privacy')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div class="pl-13" style="margin-top: 100px;">
      <span
        :class="{ active: currentLocale.code == 'en' }"
        @click="switchLanguage('en')"
        class="pointer text-3"
        >EN</span
      >
      <span
        :class="{ active: currentLocale.code == 'he' }"
        @click="switchLanguage('he')"
        class="pointer text-3"
        >HEB</span
      >
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    rightLayout: Boolean,
    drawer: Boolean
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
    currentLocale() {
      const locale = this.$i18n.locales.find(
        (i) => i.code === this.$i18n.locale
      )

      return locale
    }
  },
  watch: {
    visible(visible) {
      this.$emit('update', visible)
    },
    drawer(drawer) {
      this.visible = drawer
    }
  },
  mounted() {
    this.visible = this.drawer
  },
  methods: {
    async logout() {
      await this.$auth.logout()
    },
    login() {
      this.$emit('open-login')
    },
    switchLanguage(lang) {
      this.$i18n.setLocaleCookie(lang)
      this.$router.push(this.switchLocalePath(lang))
      this.$vuetify.rtl = lang === 'he'
    },
    flag(code) {
      switch (code) {
        case 'en':
          code = 'gb'
          break
        case 'he':
          code = 'il'
          break
      }

      return code
    },
    goProfile() {
      this.$router.push(this.localePath({ path: '/profile' }))
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  opacity: 0.85;
}

.v-navigation-drawer::v-deep .v-navigation-drawer__content {
  margin-top: 43px;
}

.v-list-item__avatar {
  left: 50%;
  margin-left: -50px;
  margin-right: 0px !important;
  margin-top: 24px;

  img {
    border: 1px solid #712fff;
  }
}
.text-center {
  text-align: center;
}
.text-1 {
  font-family: Biennale;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 23px;
}
.text-2 {
  font-family: Biennale;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 23px;
}
.text-3 {
  color: #ffffff;
  font-family: Biennale;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 18px;
}
.active {
  color: #faa337;
}
.v-list-item__action {
  margin-right: 165px !important;
  margin-left: 0px !important;
}
.fullname {
  padding-top: 0px;
  padding-bottom: 40px;
}
.auth {
  margin-top: 40px;
}
.language-changer {
  text-transform: uppercase;
  padding: 4px 0;
  .v-list-item--link {
    min-height: 32px;
    padding: 0 10px;
    .v-list-item__title {
      display: inline-block;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.language-btn {
  margin-top: 10px;
}
.flag {
  margin-right: -8px !important;
}
.pl-13 {
  padding-left: 59px;
}
.rtl-layout {
  .v-list-item__avatar {
    right: 50%;
    margin-right: -50px !important;
  }
  .text-1 {
    font-family: Heebo;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 23px;
  }
  .text-2 {
    font-family: Heebo;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.15px;
    line-height: 23px;
  }
  .text-3 {
    font-family: Heebo;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: 18px;
  }
  .v-navigation-drawer {
    padding-right: 51px;
  }
  .v-list-item__action {
    margin-right: 0px !important;
  }
  .login-text {
    order: 2;
    margin-right: 20px;
  }
  .login-icon {
    order: 1;
  }
}
</style>
