<template>
  <v-dialog
    v-model="show"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    content-class="accept-dialog"
  >
    <v-card>
      <v-card-text v-show="!!message" class="pa-4">
        <img src="/important-icon.svg" />
        <div class="text">
          <span class="bold-2"> {{ $t('aboutPage.Important') }}</span>
          <span> {{ $t('aboutPage.OnceAnAgreement') }}</span>
        </div></v-card-text
      >
      <v-card-actions class="justify-center">
        <v-btn @click.native="agree" class="v-btn" text>{{
          $t('proceed')
        }}</v-btn>
        <v-btn @click.native="cancel" class="v-btn" text>{{
          $t('cancel')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 512,
      zIndex: 200
    }
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__actions {
  padding: 12px;
}

.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
.v-card__title {
  background: white;
  color: #712fff;
  padding: 12px 0px 12px 16px !important;
  border-bottom: 1px solid #dccdff;
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 20px;
}
.close-confirmation {
  margin-right: 12px;
  cursor: pointer;
}
.v-card__text {
  padding: 20px 16px 5px 16px !important;
}
.v-btn {
  color: #712fff;
}
.text {
  width: 438px;
  float: right;
  color: #18004d;
  font-family: Biennale;
  font-size: 13px;
  letter-spacing: 0.19px;
  line-height: 22px;

  @include breakpoint(xs) {
    display: contents;
    width: auto;
  }
}
.bold-2 {
  font-weight: 600;
  font-family: 'Biennale SemiBold';
  color: #faa337;
  font-size: 14px;
}

.rtl-layout {
  .text {
    float: left;
  }
}
</style>
