<template>
  <v-dialog
    v-model="show"
    content-class="accepted-dialog"
    persistent
    max-width="480px"
  >
    <v-card v-if="show">
      <v-card-title :class="{ dummy: offer.isDummy }">
        <v-icon
          @click="show = false"
          :color="offer.isDummy ? 'black' : 'white'"
          size="30"
          class="close-button pointer"
          >mdi-close</v-icon
        >
        <span v-if="offer.isDummy" class="headline">{{
          $t('acceptedOfferModal.dummyTitle')
        }}</span>
        <span v-else-if="waiting" class="headline">{{
          $t('acceptedOfferModal.acceptedTitle')
        }}</span>
        <span v-else class="headline">{{
          $t('acceptedOfferModal.dealTitle')
        }}</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <div v-if="offer.isDummy" class="text-1 upper-text">
          {{ initiator.username }}
          {{ $t('acceptedOfferModal.willNeverKnow') }}
        </div>
        <div v-else-if="waiting" class="text-1 upper-text">
          {{ $t('acceptedOfferModal.wait') }}
          {{ waitingFor.username + $t('acceptedOfferModal.reply') }}
        </div>
        <div v-else class="text-1 upper-text">
          {{ $t('acceptedOfferModal.acceptedTitle') }}
        </div>

        <div class="wrapper">
          <div :class="{ dummy: offer.isDummy }" class="hands-container">
            <img
              v-if="offer.isDummy"
              style="margin-left:37px;margin-top: 70px;"
              src="/smile.png"
            />
            <img
              v-else-if="waiting"
              style="margin-left:56px;margin-top:80px"
              src="/watch.png"
            />
            <img v-else src="/hands.png" style="margin-top: 70px;" />
          </div>
          <div class="offer-container">
            <div class="container px-0 py-1">
              <v-row>
                <v-col cols="3" class="text-center pb-1">
                  <v-list-item-avatar v-if="!isAnonymous" class="ml-0 mt-1">
                    <img v-if="offer.isDummy" :src="initiator.avatar" />
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else>
                    <v-icon size="46" color="black">mdi-account-circle</v-icon>
                  </v-list-item-avatar>
                </v-col>
                <v-col cols="9" class="pl-0 pb-1 pt-0" align-self="end">
                  <v-list-item-content class="pb-1">
                    <div class="text-3">{{ initiator.username }}</div>
                    <div class="pt-2 pb-1 text-2">
                      <span :class="color(initiator.reputationAsInitiator)"
                        >{{ initiator.reputationAsInitiator }}%</span
                      >
                      {{ $t('offer.score') }} {{ initiator.numberOfDeals }})
                    </div>
                  </v-list-item-content>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="py-0 text-center">
                  <icon-arrow class="icon-arrow" iconColor="#712FFF" />
                </v-col>
                <v-col
                  v-if="waiting"
                  cols="5"
                  offset="4"
                  class="py-0 text-center d-none d-sm-flex"
                >
                  <icon-arrow class="icon-arrow" iconColor="#712FFF" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="3" lg="3" class="text-center pb-0">
                  <v-list-item-avatar class="ml-0 mt-1">
                    <img :src="participants[0].user.avatar" />
                  </v-list-item-avatar>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                  lg="3"
                  class="pl-0 pb-1 pt-0"
                  align-self="center"
                >
                  <v-list-item-content>
                    <div class="text-3">{{ $t('withYou') }}</div>
                  </v-list-item-content>
                </v-col>
                <v-col
                  v-if="twoParties"
                  cols="6"
                  md="3"
                  lg="3"
                  class="text-center pb-0"
                >
                  <v-list-item-avatar class="ml-0 mt-1">
                    <img :src="waitingFor.avatar" />
                  </v-list-item-avatar>
                </v-col>
                <v-col
                  v-if="twoParties"
                  cols="6"
                  md="3"
                  lg="3"
                  class="pl-0 pb-1 pt-0"
                  align-self="center"
                >
                  <v-list-item-content>
                    <div class="text-3">{{ waitingFor.username }}</div>
                  </v-list-item-content>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center pt-0 px-4">
                  <div class="text-4 name">“{{ offer.name }}”</div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <v-checkbox
          v-if="offer.isDummy && offer.presentAllowNotification"
          v-model="notify"
          :hide-details="true"
          color="#712FFF"
          class="pt-0 mt-1 px-4"
        >
          <template slot="label">
            <div class="text-1">
              {{ $t('acceptedOfferModal.notify') }}{{ initiator.username
              }}{{ $t('acceptedOfferModal.noMoreOffers') }}
            </div>
          </template>
        </v-checkbox>

        <div v-if="waiting" class="text-1 px-5 pt-5">
          <v-icon color="#712FFF" class="pr-1">mdi-help-circle-outline</v-icon>
          {{ $t('acceptedOfferModal.only') }}
          {{ waitingFor.username }}
          {{ $t('acceptedOfferModal.acceptesOffer') }}
        </div>

        <div v-else-if="!offer.isDummy" class="text-1 px-5 pt-5">
          <v-icon color="#712FFF" class="pr-1">mdi-bell-outline</v-icon>
          {{ twoParties ? waitingFor.username : initiator.username }}
          {{ $t('acceptedOfferModal.notifiedNow') }}
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn @click="sendNoMoreOpenNotification()" color="#712fff" text>{{
          $t('OK')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import IconArrow from '@/components/icons/IconArrow.vue'

export default {
  components: {
    IconArrow
  },
  data() {
    return {
      dialog: false,
      notify: true,
      resolve: null,
      reject: null
    }
  },
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    },
    initiator() {
      const party = this.offer.parties.find(
        (party) => party.partyRole === 'initiator'
      )

      if (this.offer.type === 'Anonymous') {
        return party
      } else {
        return party.user
      }
    },
    waitingFor() {
      let party = {}
      if (this.offer.status === 'accepted') {
        party = this.offer.parties.find(
          (party) =>
            party.user.username !== this.$auth.user.username &&
            party.partyRole === 'participant'
        )
      } else {
        party = this.offer.parties.find((party) => party.status === 'pending')
        if (!party) {
          party = this.offer.parties.find(
            (party) =>
              party.user.username !== this.$auth.user.username &&
              party.partyRole === 'participant' &&
              party.status === 'rejected'
          )
        }
      }

      return party.user
    },
    participants() {
      const party = this.offer.parties.filter(
        (party) => party.partyRole === 'participant'
      )

      return party
    },
    participantsScore() {
      if (this.participants.length === 1) {
        return [
          this.participants[0].user.reputationAsParticipant,
          this.participants[0].user.participantOfferAccepted
        ]
      } else {
        return [
          this.participants[0].user.reputationAsParticipant,
          this.participants[1].user.reputationAsParticipant
        ]
      }
    },
    waiting() {
      if (this.participants.length === 2 && this.offer.status === 'opened') {
        return true
      }
      return false
    },
    twoParties() {
      if (this.participants.length === 2) {
        return true
      }
      return false
    },
    isAnonymous() {
      return this.offer.type === 'Anonymous'
    }
  },
  watch: {
    show(val) {
      if (!val) {
        if (this.offer.isDummy) {
          this.$bus.$emit('offer-deleted', this.offer.id)
        }
      }
    }
  },
  methods: {
    open(offer) {
      this.show = true
      this.offer = offer
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    initiatorUsername() {
      if (this.offer.isAnonymous()) {
        return 'Anonymous'
      } else {
        return this.initiator().username
      }
    },
    initiatorRating() {
      return this.initiator().reputationAsInitiator
    },
    async sendNoMoreOpenNotification() {
      if (
        this.offer.presentAllowNotification &&
        this.notify &&
        this.offer.isDummy
      ) {
        await this.$offerService.sendNoMoreOpenNotification(this.offer.id)
      }

      this.show = false
    },
    color(rating) {
      let color = ''

      if (rating < 50) {
        color = 'red-text'
      } else if (rating < 75) {
        color = 'yellow-text'
      } else {
        color = 'green-text'
      }

      return color
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  background: #712fff;
  color: white;
  padding: 30px 0px 30px 16px !important;

  .headline {
    font-family: Oswald !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.v-card__actions {
  padding: 12px;
  background: #f4f3f7;
}

.v-card__text {
  background: #f4f3f7;
}

.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.close-button {
  margin-right: 18px;
}

.text-1 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.text-2 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #210759;
}
.text-3 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #210759;
}
.text-4 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #210759;
}
.green-text {
  color: #00ff75;
}
.yellow-text {
  color: #fdbe00;
}
.red-text {
  color: #ff4438;
}
.black-text {
  color: black;
}

.wrapper {
  // height: 247px;
  width: 100%;
}

.upper-text {
  padding-top: 24px;
  padding-bottom: 17px;
  padding-left: 17px;
}
.hands-container {
  float: left;
  width: 179px;
  height: 247px;
  background-color: #712fff;
}
.offer-container {
  // height: 247px;
  background-color: white;
}
.bottom-text {
  padding-left: 20px;
  padding-top: 18px;
  padding-bottom: 20px;

  i {
    padding-right: 5px;
  }
}
.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-right: 0px;
}
.name {
  border: 1px solid #210759;
  padding-bottom: 9px;
  padding-top: 7px;
  border-radius: 10px 10px 0px 0px;
}
.dummy {
  background: #fdbe00;
  color: black;
}
.v-card__text::v-deep .mdi-checkbox-blank-outline {
  color: #712fff;
}
.v-card__text::v-deep .v-input--checkbox .v-label {
  padding-top: 16px;
}
</style>
