<template lang="pug">
  <v-app :class="{ 'rtl-layout' : $i18n.locale == 'he'}">
    <svg  :class="{ 'd-none' : isHome}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="background-triangle"preserveAspectRatio="none">
      <polygon fill="#ddcdff" points="50,100 100,0 100,100"/>
    </svg>
    <HomeNav v-if="isHome" />
    <UsersNav v-else-if="$auth.loggedIn" />
    <GuestsNav v-else />
    <v-content class="app-content">
      <OfferModal v-model="showOfferModal" :offer="offer" />
      <confirm ref="confirm"></confirm>
       <accept ref="accept"></accept>
      <nuxt />
    </v-content>
  </v-app>
</template>

<script>
import UsersNav from '@/components/navdrawers/UsersNav.vue'
import HomeNav from '@/components/navdrawers/HomeNav.vue'
import GuestsNav from '@/components/navdrawers/GuestsNav.vue'
import OfferModal from '~/components/modals/OfferModal'
import Confirm from '@/components/modals/Confirm'
import Accept from '@/components/modals/Accept'

export default {
  components: {
    UsersNav,
    HomeNav,
    GuestsNav,
    OfferModal,
    Confirm,
    Accept
  },
  head() {
    //
    return {
      title: 'GetRealLab',
      script: [
        {
          src: `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`
        },
        {
          src: 'https://js.stripe.com/v3/'
        }
      ]
    }
  },
  data() {
    return {
      showOfferModal: false,
      offer: {}
    }
  },
  computed: {
    isHome() {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/about' ||
        this.$route.path === '/he' ||
        this.$route.path === '/he/about' ||
        this.$route.path === '/about/' ||
        this.$route.path === '/he/about/' ||
        this.$route.path === '/he/'
      )
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm
    this.$root.$accept = this.$refs.accept
    this.$bus.$on('open-offer', (offer) => {
      this.offer = offer
      this.showOfferModal = true
    })
  },
  created() {
    if (this.$i18n.locale === 'he') {
      this.$vuetify.rtl = true
      this.$moment.locale('he')
    }
  }
}
</script>

<style lang="scss">
svg.background-triangle {
  position: fixed;
  top: 71px;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.v-menu__content.card-menu {
  .primary--text.v-list-item--active {
    color: #712fff !important;
  }
  .v-list {
    padding: 6px 0;
  }
  .v-list-item {
    min-height: 30px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
  .v-list-item__content {
    padding: 7px 0;
  }
}
.v-list .v-list-item--active {
  color: #712fff !important;
}

.notifications-nav {
  margin-top: 17px;
}

.toasted {
  font-family: Lato;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;

  .action {
    text-decoration: none;
  }
}
</style>
