export default ($axios) => ({
  async me() {
    const user = (await $axios.get('api/me/')).data
    return user
  },

  async register(credentials) {
    const token = (await $axios.post('api/signUp/', credentials)).data
      .access_token
    return token
  },

  async forgetPassword(credentials) {
    const request = await $axios.post('/api/resetPasswordRequest', credentials)
    return request
  },

  async resetPassword(credentials) {
    const request = await $axios.post('/api/resetPassword', credentials)
    return request
  },

  async changePassword(credentials) {
    const request = await $axios.post('/api/users/changePassword', credentials)
    return request
  },

  async getUserByEmail(email) {
    const user = await $axios.get('api/users?email=' + email)
    return user.data
  },

  async getUserByPhone(phone) {
    const user = await $axios.get('api/users?phone=%2B' + phone.substr(1))
    return user.data
  },

  async uploadAvatar(file) {
    const url = 'api/users/Avatar'
    const request = await $axios.post(url, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return request
  },

  async getEmails() {
    const request = await $axios.get('/api/users/mails/')
    return request
  },

  async addEmail(email) {
    const request = await $axios.post('/api/users/mail/' + email)
    return request
  },

  async deleteEmail(email) {
    const request = await $axios.delete('/api/users/mail/' + email)
    return request
  },

  async getCards() {
    const request = await $axios.get('api/users/card/')
    return request.data
  },

  async addCard(card) {
    const request = await $axios.post('/api/users/card/', card)
    return request
  },

  async delCard(cardId) {
    const request = await $axios.delete('/api/users/card/' + cardId)
    return request
  },

  async getPhones() {
    const request = await $axios.get('/api/users/phones/')
    return request
  },

  async addPhone(phone) {
    const request = await $axios.post('/api/users/phone/' + phone)
    return request
  },

  async deletePhone(phone) {
    const request = await $axios.delete('/api/users/phone/' + phone)
    return request
  },

  async changeUsername(credentials) {
    const request = await $axios.post('/api/users/changeUsername/', credentials)
    return request
  },

  async changeStripeCustomer(credentials) {
    const request = await $axios.post(
      '/api/users/changeStripeCustomer/',
      credentials
    )
    return request
  },

  async updateStripeCustomer(credentials) {
    const request = await $axios.post(
      '/api/users/updateStripeCustomer/',
      credentials
    )
    return request
  },

  async receiveNotifications(credentials) {
    const request = await $axios.post(
      '/api/users/receiveNotifications/',
      credentials
    )
    return request
  },

  async removeAccount() {
    const request = await $axios.delete('api/users/removeAccount')
    return request
  }
})
