export default ($axios) => ({
  async getOffers(credentials) {
    const params = credentials

    const offers = await $axios.get('api/offers', {
      params
    })
    return offers
  },

  async getOffersHistory(credentials) {
    const params = credentials

    const offers = await $axios.get('api/offers/myPastDeals', {
      params
    })
    return offers
  },

  async getOffer(offerId) {
    const offer = await $axios.get('api/offers/' + offerId)
    return offer
  },

  async saveDraft(credentials) {
    const id = await $axios.post('api/offers', credentials)
    return id
  },

  async sendOffer(offerId, additionalPaymentDone) {
    const url = 'api/offers/' + offerId + '/send'
    const id = await $axios.post(url, { additionalPaymentDone })
    return id
  },

  async deleteOffer(offerId) {
    const request = await $axios.delete('api/offers/' + offerId)
    return request
  },

  async editOffer(offerId, credentials) {
    const id = await $axios.post('api/offers/' + offerId, credentials)
    return id
  },

  async duplicateOffer(offerId) {
    const url = `api/offers/${offerId}/duplicate`
    const id = await $axios.post(url)
    return id
  },

  async acceptOffer(offerId) {
    const url = 'api/offers/' + offerId + '/parties/accept'
    const id = await $axios.post(url)
    return id
  },

  async rejectOffer(offerId) {
    const url = 'api/offers/' + offerId + '/parties/reject'
    const id = await $axios.post(url)
    return id
  },

  async offerAnonymousAcceptance(offerId, credentials) {
    const url = 'api/offers/' + offerId + '/offerAnonymousAcceptance'
    const id = await $axios.post(url, credentials)
    return id
  },

  async uploadFiles(offerId, files) {
    const url = 'api/offers/' + offerId + '/attachments'
    const id = await $axios.post(url, files, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return id
  },

  async deleteFiles(offerId, files) {
    const url = 'api/offers/' + offerId + '/attachments'
    const id = await $axios.delete(url, { data: files })
    return id
  },

  async sendNoMoreOpenNotification(offerId) {
    const url = `/api/offers/${offerId}/noMoreOpenNotification`
    await $axios.post(url)
  },

  async sendFeedback(offerId, feedback) {
    const url = `/api/offers/${offerId}/feedback`
    await $axios.post(url, { feedback })
  }
})
