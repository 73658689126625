<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      persistent
      max-width="480px"
      content-class="mx-0"
    >
      <v-card>
        <v-card-title>
          <v-icon
            @click="dialog = true"
            size="30"
            color="white"
            class="close-button pointer"
            >mdi-close</v-icon
          >
          <span class="headline">{{ headline }}</span>
        </v-card-title>
        <v-card-text ref="cardText">
          <v-container>
            <Notification v-if="error" :message="error" />
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="3" class="py-0">
                  <div class="title-1 offer-type">
                    {{ $t('newOfferModal.type') }}
                  </div>
                </v-col>
                <v-col cols="9" class="py-0 offer-select">
                  <v-overflow-btn
                    v-model="offerType"
                    :items="offerTypes"
                    :label="$t('newOfferModal.selectTemplate')"
                    :hide-details="true"
                    menu-props="closeOnClick, closeOnContentClick"
                    class="pt-0"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" class="pt-0 px-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <div class="title-1">{{ $t('newOfferModal.dealFor') }}</div>
                  <v-radio-group v-model="dealFor" :hide-details="true">
                    <v-radio
                      :label="$t('newOfferModal.part')"
                      value="1"
                      color="#712fff"
                      class="modal-radio"
                    ></v-radio>
                    <v-radio
                      :label="$t('newOfferModal.matchMaker')"
                      value="2"
                      color="#712fff"
                      class="modal-radio"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col v-show="dealFor === '1' && offerType != 5" cols="12">
                  <div class="title-2">
                    {{ $t('newOfferModal.dummyTitle') }}
                  </div>
                  <v-radio-group v-model="isDummy" :hide-details="true">
                    <v-radio
                      :label="$t('newOfferModal.notDummy')"
                      value="0"
                      color="#712fff"
                      class="modal-radio"
                    ></v-radio>
                    <v-radio value="1" color="#712fff" class="modal-radio">
                      <template slot="label">
                        <div>
                          {{ $t('newOfferModal.dummy') }}
                          <v-tooltip
                            :open-on-click="true"
                            :open-on-hover="false"
                            max-width="460px"
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <span
                                @click.stop.prevent
                                v-on="on"
                                class="purple-lighter-text"
                              >
                                {{ $t('newOfferModal.learnMore') }}
                                <v-icon
                                  size="18"
                                  color="#9c6eff"
                                  style="margin-top: -5px;"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </span>
                            </template>
                            <span>{{ $t('newOfferModal.tooltip') }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <User v-model="firstSide" :title="title1" />
              <User
                v-show="dealFor === '2'"
                v-model="secondSide"
                :title="title2"
              />
              <v-row>
                <v-col cols="12" class="py-0 px-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <div class="title-1">{{ $t('newOfferModal.nameTitle') }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    :label="$t('newOfferModal.title')"
                    :rules="nameRule"
                    filled
                    required
                    single-line
                  ></v-text-field>
                  <v-textarea
                    v-model="description"
                    :label="$t('newOfferModal.moreInfo')"
                    :hint="$t('newOfferModal.optional')"
                    :rules="descriptionRule"
                    filled
                    single-line
                    persistent-hint
                    rows="3"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="py-0 px-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-checkbox
                    v-model="money"
                    :hide-details="true"
                    color="#712FFF"
                  >
                    <template slot="label">
                      <div class="title-1">
                        {{ $t('newOfferModal.moneyInvolving') }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col v-show="money" cols="12" class="py-0">
                  <div class="inline-flex amount-container">
                    <div class="title-5">{{ $t('newOfferModal.amount') }}</div>
                    <v-textarea
                      v-model="amount"
                      :hide-details="true"
                      filled
                      single-line
                      persistent-hint
                      rows="1"
                      class="amount"
                    ></v-textarea>
                    <v-overflow-btn
                      v-model="currency"
                      :items="currencies"
                      :hide-details="true"
                      menu-props="closeOnClick, closeOnContentClick"
                      class="currency pt-0"
                    ></v-overflow-btn>
                  </div>
                  <div style="height: 56px;">
                    <div @click="swap" class="inline-flex pointer">
                      <div class="title-6">{{ payer }}</div>
                      <div class="title-5 pay-text">
                        {{ $t('newOfferModal.pay') }}
                        <v-icon v-if="this.$i18n.locale === 'he'"
                          >mdi-arrow-left</v-icon
                        >
                        <v-icon v-else>mdi-arrow-right</v-icon>
                      </div>
                      <div class="title-6">{{ recipient }}</div>
                    </div>
                  </div>
                  <v-checkbox
                    v-model="automaticPayment"
                    :hide-details="true"
                    color="#712FFF"
                    class="mb-3 mt-0"
                  >
                    <template slot="label">
                      <div class="title-1">
                        {{ $t('newOfferModal.automaticPayment') }}
                      </div>
                    </template>
                  </v-checkbox>
                  <div v-show="amount && automaticPayment" class="title-5 mb-3">
                    {{ $t('newOfferModal.includingFee') }}
                    {{ includingFee.toFixed(2) }}
                    {{ currency }}
                    <v-tooltip
                      v-model="learnMore"
                      :open-on-click="true"
                      :open-on-hover="false"
                      max-width="460px"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <span
                          @click.stop.prevent
                          v-on="on"
                          class="purple-lighter-text"
                        >
                          {{ $t('newOfferModal.learnMore') }}
                          <v-icon
                            size="18"
                            color="#9c6eff"
                            style="margin-top: -5px;"
                            >mdi-help-circle-outline</v-icon
                          >
                        </span>
                      </template>
                      <span>{{ $t('newOfferModal.feeTooltip') }}</span>
                    </v-tooltip>
                  </div>
                  <card
                    v-show="amount && automaticPayment && payer === 'Me'"
                    v-if="mountStripeElement"
                    :class="{ complete }"
                    :options="stripeOptions"
                    @change="complete = $event.complete"
                    :stripe="stripeKey"
                    class="stripe-card"
                  />
                </v-col>
                <v-col cols="12" class="py-0 px-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-checkbox
                    v-model="timeLimit"
                    :hide-details="true"
                    color="#712FFF"
                  >
                    <template slot="label">
                      <div class="title-1">
                        {{ $t('newOfferModal.timeLimit') }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col v-show="timeLimit" cols="12" class="py-0 inline-flex">
                  <div class="title-4">
                    {{ $t('newOfferModal.offerValid') }}
                  </div>
                  <v-menu
                    v-model="datepicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="date"
                        v-on="on"
                        prepend-icon="mdi-calendar"
                        readonly
                        class="date-field"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="datepicker = false"
                      :min="now"
                      :max="maxDate"
                    ></v-date-picker>
                  </v-menu>
                  <div class="title-4">{{ $t('newOfferModal.addLimit') }}</div>
                </v-col>
                <v-col cols="12" class="py-0 px-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <input
                    ref="files"
                    v-show="false"
                    @change="handleFileUpload()"
                    type="file"
                    multiple
                  />
                  <div @click="triggerFileUpload" class="title-1 pointer">
                    {{ $t('newOfferModal.addPicures') }}
                    <v-icon color="#712FFF">mdi-paperclip</v-icon>
                  </div>
                  <div @click="triggerFileUpload" class="title-3 pointer">
                    {{ $t('newOfferModal.sizeLimit') }}
                  </div>
                  <v-row>
                    <v-col
                      v-for="(preview, i) in offerAttachments"
                      :key="i"
                      cols="12"
                      md="6"
                    >
                      <v-img
                        v-if="isImage(preview.type)"
                        :src="preview.thumbnailUrl"
                        height="150"
                        class="text-right pa-2"
                      >
                        <v-btn
                          @click="deleteFile(i, preview.key)"
                          icon
                          style="background-color: white;"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-row
                          align="end"
                          class="lightbox white--text fill-height"
                        >
                          <v-col class="filename py-2">
                            <div class="body-1">
                              {{ fileName(preview.key, true) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-img>
                      <v-img
                        v-else
                        src="/file.png"
                        height="150"
                        class="text-right pa-2 file"
                      >
                        <v-btn
                          @click="deleteFile(i, preview.key)"
                          icon
                          style="background-color: white;"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-row
                          align="end"
                          class="lightbox white--text fill-height"
                        >
                          <v-col class="filename py-2">
                            <div class="body-1">
                              {{ fileName(preview.key, true) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-img>
                    </v-col>
                    <v-col
                      v-for="(preview, i) in previews"
                      :key="i + 7"
                      cols="12"
                      md="6"
                    >
                      <v-img
                        :src="preview"
                        :class="{ file: preview === '/file.png' }"
                        height="150"
                        class="text-right pa-2"
                      >
                        <v-btn
                          @click="deletePreview(i)"
                          icon
                          style="background-color: white;"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-row
                          align="end"
                          class="lightbox white--text fill-height"
                        >
                          <v-col class="filename py-2">
                            <div class="body-1">
                              {{ fileName(files[i].name) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <PaymentModal ref="paymentModal" />
            <v-dialog
              v-model="dialog"
              persistent
              max-width="480"
              content-class="cancel-modal mx-0"
            >
              <v-card>
                <v-card-title class="headline">
                  <v-icon
                    @click="dialog = false"
                    size="23"
                    color="#712fff"
                    class="close-confirmation"
                    >mdi-close</v-icon
                  >
                  {{ $t('newOfferModal.cancelTitle') }}
                </v-card-title>
                <v-card-text>{{ $t('newOfferModal.cancelText') }}</v-card-text>
                <v-card-actions>
                  <v-btn @click="dialog = false" text>{{ $t('cancel') }}</v-btn>
                  <v-btn @click="saveDraft" text>{{
                    $t('newOfferModal.save')
                  }}</v-btn>
                  <v-btn @click="close" text>{{
                    $t('newOfferModal.close')
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="stripeDialog"
              persistent
              max-width="480"
              content-class="cancel-modal mx-0"
            >
              <v-card>
                <v-card-title class="headline">
                  <v-icon
                    @click="dialog = false"
                    size="23"
                    color="#712fff"
                    class="close-confirmation"
                    >mdi-close</v-icon
                  >
                  {{ $t('newOfferModal.payOfferTitle') }}
                </v-card-title>
                <v-card-text
                  >{{ $t('newOfferModal.payOfferText') }}
                  <card
                    :class="{ complete }"
                    :options="stripeOptions"
                    @change="complete = $event.complete"
                    :stripe="stripeKey"
                    class="stripe-card"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="stripeDialog = false" text>{{
                    $t('cancel')
                  }}</v-btn>
                  <div class="flex-grow-1"></div>
                  <v-btn @click="payOffer" text>{{ $t('continue') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-overlay :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = true" color="#712fff" text>{{
            $t('cancel')
          }}</v-btn>
          <div class="flex-grow-1"></div>
          <v-btn @click="saveDraft" color="#712fff" text>
            {{ $t('newOfferModal.save') }}
          </v-btn>
          <v-btn @click="review" color="#712FFF" dark>{{
            $t('newOfferModal.review')
          }}</v-btn>
          <v-btn @click="createOffer" color="#712fff" text>{{
            $t('newOfferModal.send')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  Card,
  createToken
  // instance as StripeInstance
} from 'vue-stripe-elements-plus'
// import { Card, paymentRequest, createToken } from 'vue-stripe-elements-plus'
import Notification from '@/components/Notification'
import User from '@/components/User'
import PaymentModal from '@/components/modals/PaymentModal'

export default {
  components: {
    Notification,
    User,
    PaymentModal,
    Card
  },
  props: {
    value: Boolean,
    offer: Object,
    newOffer: Boolean
  },
  data() {
    this.stripeKey = process.env.STRIPE_PK
    return {
      valid: false,
      dealFor: '1',
      firstSide: {},
      secondSide: {},
      name: '',
      description: '',
      isDummy: '0',
      amount: '',
      currency: 'USD',
      payer: '',
      recipient: 'Me',
      money: false,
      automaticPayment: false,
      feePercentage: 0,
      swapped: false,
      timeLimit: false,
      error: null,
      offerType: null,
      dialog: false,
      stripeDialog: false,
      additionalPaymentDone: false,
      learnMore: false,
      date: new Date().toISOString().substr(0, 10),
      now: new Date().toISOString().substr(0, 10),
      datepicker: false,
      files: [],
      previews: [],
      deletePreviews: [],
      overlay: false,
      title1: this.$t('newOfferModal.with'),
      title2: 'Side2',
      idStripe: 0,
      offerTypes: [
        { text: this.$t('newOfferModal.none'), value: 0 },
        { text: this.$t('newOfferModal.compromise'), value: 1 },
        {
          text: this.$t('newOfferModal.anonymous'),
          value: 5
        }
      ],
      currencies: ['USD', 'ILS'],
      nameRule: [
        (value) => !!value || this.$t('required'),
        (value) => (value || '').length >= 4 || this.$t('error.min'),
        (value) => (value || '').length <= 70 || this.$t('error.max70')
      ],
      descriptionRule: [
        (value) => (value || '').length <= 1000 || this.$t('error.max1000')
      ],
      complete: false,
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      mountStripeElement: false,
      user: {}
    }
  },
  computed: {
    maxDate() {
      const date = new Date() + 14
      return date
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    offerParties() {
      const offerParties = this.firstSide.isPhone
        ? [{ phone: this.firstSide.val }]
        : [{ email: this.firstSide.val }]

      if (this.dealFor === '2') {
        const secondParty = this.secondSide.isPhone
          ? { phone: this.secondSide.val }
          : { email: this.secondSide.val }
        offerParties.push(secondParty)
      }

      return offerParties
    },
    offerDetails() {
      const dummyOffer = parseInt(this.isDummy)
      const offerDetails = {
        type: this.offerType === 5 ? 'Anonymous' : 'Regular',
        name: this.name,
        description: this.description,
        isDummy: !!dummyOffer,
        offerPartiesCreationEntities: this.offerParties
      }

      if (this.timeLimit) {
        offerDetails.dueDate = this.date + 'T23:59:59.000Z'
      }

      if (this.money) {
        offerDetails.amount = this.amount
        offerDetails.currency = this.currency
        offerDetails.payer = this.payerParty
        offerDetails.payedViaGetRealLab = this.automaticPayment
      }

      return offerDetails
    },
    payerParty() {
      if (this.payer === 'Me') {
        return 'Me'
      } else if (
        this.payer === this.firstSide.val ||
        this.payer === this.firstSide.user.username
      ) {
        return 'FirstParty'
      } else {
        return 'SecondParty'
      }
    },
    headline() {
      return this.newOffer
        ? this.$t('newOfferModal.create')
        : this.$t('newOfferModal.edit')
    },
    offerAttachments() {
      if (this.offer) {
        return this.offer.attachments
      }

      return null
    },
    includingFee() {
      return this.amount * (1 + this.feePercentage / 100)
    }
  },
  watch: {
    offerType(type) {
      switch (type) {
        case 0:
          this.clearForm()
          break
        case 1:
          this.compromise()
          break
        case 2:
          this.matchMaking()
          break
        case 3:
          this.agreeSecondDate()
          break
        case 4:
          this.disagreeSecondDate()
          break
        case 5:
          this.anonymousOffer()
          break
      }
    },
    show(val) {
      if (!val) {
        this.clearForm()
      }
    },
    firstSide: {
      handler(side) {
        this.updateParties()
      },
      deep: true
    },
    secondSide: {
      handler(side) {
        this.updateParties()
      },
      deep: true
    },
    dealFor(val) {
      if (val === '2') {
        this.title1 = 'Side 1'
        this.updateRecipient(this.secondSide)
        this.updatePayer(this.firstSide)
      } else {
        this.title1 = 'With'
        this.updatePayer(this.firstSide)
        this.recipient = 'Me'
      }
    },
    money(val) {
      if (val) {
        this.timeLimit = true
        const date = new Date()
        date.setDate(date.getDate() + 3)
        this.date = date.toISOString().substr(0, 10)
      }
    },
    async automaticPayment(val) {
      if (val) {
        const fee = await this.$paymentService.feePercentage()
        this.feePercentage = fee.data
      }
    }
  },
  async mounted() {
    this.mountStripeElement = true
    const date = new Date()
    date.setDate(date.getDate() + 14)
    this.date = date.toISOString().substr(0, 10)

    if (!this.newOffer) {
      const party = this.offer.parties.filter(
        (party) => party.partyRole === 'participant'
      )
      if (party.length === 1) {
        this.dealFor = '1'
        this.isDummy = this.offer.isDummy ? '1' : '0'
        this.firstSide = { val: party[0].contactDetailUsed, initIsValid: true }
      } else {
        this.dealFor = '2'
        this.firstSide = { val: party[0].contactDetailUsed, initIsValid: true }
        this.secondSide = { val: party[1].contactDetailUsed, initIsValid: true }
      }

      if (this.offer.dueDate) {
        this.timeLimit = true
        this.date = this.offer.dueDate.substr(0, 10)
      }

      this.name = this.offer.name
      this.description = this.offer.description
      this.money = this.offer.amount > 0

      if (this.money) {
        this.amount = this.offer.amount
        this.currency = this.offer.currency
        this.automaticPayment = this.offer.payedViaGetRealLab
        this.setSwapped()
      }
    }
    this.$bus.$on('send-offer', () => {
      this.createOffer()
    })
    const user = await this.$userService.me()
    this.user = user.user
  },
  methods: {
    async saveDraft() {
      if (this.validate()) {
        this.overlay = true
        const draft = this.newOffer
          ? await this.saveDraftToDB()
          : await this.updateOffer()

        if (draft) {
          const toastText = this.newOffer ? 'Draft Saved!' : 'Draft Edited!'
          const eventName = this.newOffer ? 'draft-saved' : 'draft-edited'
          const data = this.newOffer ? draft.offerId : draft
          this.$toast.success(toastText)
          this.$bus.$emit(eventName, data)
          this.show = false
          this.overlay = false
        }
      }
    },
    async createOffer() {
      if (this.validate()) {
        this.overlay = true
        let offer
        const draft = this.newOffer
          ? await this.saveDraftToDB()
          : await this.updateOffer()

        if (draft) {
          const id = this.newOffer ? draft.offerId : draft.id

          if (this.money && this.automaticPayment && this.payer === 'Me') {
            // try {
            //   let payment = await this.$paymentService.authorizePaymentIntent(
            //     id
            //   )
            //   const paymentId = payment.data.links
            //     .find((link) => link.rel === 'approval_url')
            //     .href.slice(-20)
            //   const paymentDetails = await this.$refs.paymentModal.open(
            //     paymentId
            //   )
            //   if (paymentDetails) {
            //     payment = await this.$paymentService.addPaymentDetails(
            //       id,
            //       paymentDetails.payerID,
            //       paymentDetails.orderID,
            //       paymentDetails.paymentID
            //     )
            //   } else {
            //     await this.$offerService.deleteOffer(draft.offerId)
            //     this.overlay = false
            //     return
            //   }
            // } catch (e) {
            //   this.error = e.response.data.errorMessage
            //   this.$refs.cardText.scrollTop = 0
            //   this.overlay = false
            //   return
            // }
            try {
              await this.createStripeIntent(id, {
                amount: +(this.includingFee * 100).toFixed(0),
                currency: this.currency,
                userId: this.user.userId
              })
            } catch (e) {
              this.error = e
              this.$refs.cardText.scrollTop = 0
              this.overlay = false
              return
            }
          }
          try {
            offer = await this.$offerService.sendOffer(
              id,
              this.additionalPaymentDone
            )
          } catch (e) {
            this.error = e.response.data.errorMessage
            this.$refs.cardText.scrollTop = 0
            this.overlay = false
            return
          }

          if (
            offer.data === 'stripePaymentRequired' &&
            !this.additionalPaymentDone
          ) {
            this.mountStripeElement = false
            this.stripeDialog = true
            this.overlay = false
            this.idStripe = id
          } else {
            this.$toast.success('Offer Sent!', { duration: 1000 })
            this.additionalPaymentDone = false
            const eventName = this.newOffer ? 'offer-created' : 'draft-edited'
            this.$bus.$emit(eventName, offer.data)
            this.show = false
            this.overlay = false
          }
        }
      }
    },
    async saveDraftToDB() {
      let request

      try {
        request = await this.$offerService.saveDraft(this.offerDetails)
      } catch (e) {
        this.error = e.response.data.errorMessage
        this.$refs.cardText.scrollTop = 0
        this.overlay = false
        return false
      }

      try {
        await this.uploadFiles(request.data.offerId)
      } catch (e) {
        if (e.response.data.errorCode === 1010005) {
          this.error = this.$t('error.TooLargeFile')
        } else {
          this.error = e.response.data.errorMessage
        }

        this.$refs.cardText.scrollTop = 0
        this.overlay = false
        return false
      }

      return request.data
    },
    async updateOffer() {
      let request

      try {
        await this.uploadFiles(this.offer.id)
      } catch (e) {
        if (e.response.data.errorCode === 1010005) {
          this.error = this.$t('error.TooLargeFile')
        } else {
          this.error = e.response.data.errorMessage
        }

        this.$refs.cardText.scrollTop = 0
        this.overlay = false
        return false
      }

      try {
        this.deleteFiles()
      } catch (e) {
        this.error = e.response.data.errorMessage
        this.$refs.cardText.scrollTop = 0
        this.overlay = false
        return false
      }

      try {
        request = await this.$offerService.editOffer(
          this.offer.id,
          this.offerDetails
        )
      } catch (e) {
        this.error = e.response.data.errorMessage
        this.$refs.cardText.scrollTop = 0
        this.overlay = false
        return false
      }

      return request.data
    },
    async uploadFiles(offerId) {
      if (this.files.length) {
        const formData = new FormData()
        for (let i = 0; i < this.files.length; i++) {
          const file = this.files[i]

          formData.append('upload', file)
        }

        await this.$offerService.uploadFiles(offerId, formData)
      }
    },
    async payOffer() {
      try {
        await this.createStripeIntent(
          this.idStripe,
          {
            amount: +(15 * 100).toFixed(0),
            currency: this.currency,
            userId: this.user.userId
          },
          true
        )
        this.stripeDialog = false
        this.additionalPaymentDone = true
      } catch (e) {
        this.error = e
        this.$refs.cardText.scrollTop = 0
        this.overlay = false
      }
    },
    clearForm() {
      this.dealFor = '1'
      this.firstSide = {}
      this.secondSide = {}
      this.name = ''
      this.description = ''
      this.isDummy = '0'
      this.money = false
      this.amount = null
      this.timeLimit = false
      const date = new Date()
      date.setDate(date.getDate() + 14)
      this.date = date.toISOString().substr(0, 10)
      this.error = null
      this.files = []
      this.previews = []
      this.$refs.form.resetValidation()
      this.$refs.cardText.scrollTop = 0
    },
    compromise() {
      this.dealFor = '1'
      this.name = this.$t('type.compromise.name')
      this.description = this.$t('type.compromise.description')
      this.isDummy = '0'
      this.money = true
      this.timeLimit = false
    },
    anonymousOffer() {
      this.dealFor = '2'
      this.name = ''
      this.description = ''
      this.isDummy = '0'
      this.money = false
      this.timeLimit = false
    },
    close() {
      this.dialog = false
      this.show = false
    },
    triggerFileUpload() {
      this.$refs.files.click()
    },
    validate() {
      if (
        this.$refs.form.validate() &&
        this.validateParties() &&
        this.validatePayment()
      ) {
        return true
      }
    },
    validateParties() {
      if (this.dealFor === '1') {
        if (this.firstSide.isValid) {
          return true
        } else {
          this.error = this.$t('error.InvalidWith')
          this.$refs.cardText.scrollTop = 0
          return false
        }
      } else if (this.dealFor === '2') {
        if (this.firstSide.isValid && this.secondSide.isValid) {
          return true
        } else {
          this.error = this.$t('error.InvalidWith')
          this.$refs.cardText.scrollTop = 0
          return false
        }
      }
    },
    validatePayment() {
      if (this.money) {
        const amount = parseInt(this.amount)
        if (amount > 0) {
          return true
        } else {
          this.error = this.$t('error.amount')
          this.$refs.cardText.scrollTop = 0
          return false
        }
      }
      return true
    },
    handleFileUpload() {
      const uploadedFiles = this.$refs.files.files

      /*
          Adds the uploaded file to the files array
        */
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (this.files.length >= 8) {
          this.error = this.$t('newOfferModal.errorFilesLength')
          break
        } else if (uploadedFiles[i].size > 8 * 1024 * 1024) {
          this.error = this.$t('newOfferModal.errorFilesSize')
          this.$refs.cardText.scrollTop = 0
          break
        } else {
          this.files.push(uploadedFiles[i])
        }
      }

      /*
          Generate image previews for the uploaded files
        */
      this.getImagePreviews()
    },
    getImagePreviews() {
      this.previews = []
      /*
          Iterate over all of the files and generate an image preview for each one.
        */
      for (let i = 0; i < this.files.length; i++) {
        /*
            Ensure the file is an image file
          */
        if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
          /*
              Create a new FileReader object
            */
          const reader = new FileReader()

          /*
              Add an event listener for when the file has been loaded
              to update the src on the file preview.
            */
          reader.onload = function() {
            this.previews.push(reader.result)
          }.bind(this)

          /*
              Read the data for the file in through the reader. When it has
              been loaded, we listen to the event propagated and set the image
              src to what was loaded from the reader.
            */
          reader.readAsDataURL(this.files[i])
        } else {
          this.previews.push('/file.png')
        }
      }
    },
    fileName(fullName, fromServer) {
      if (fromServer) {
        fullName = fullName.substr(20)
      }
      let name = fullName
      if (fullName.length > 21) {
        const type = fullName.slice(-3)
        name = name.substring(0, 16) + '....' + type
      }

      return name
    },
    review() {
      if (this.validate()) {
        const previewOffer = {
          type: this.offerType === 5 ? 'Anonymous' : 'Regular',
          name: this.name,
          description: this.description,
          isDummy: !!parseInt(this.isDummy),
          review: true,
          parties: [
            {
              status: 'accepted',
              partyRole: 'initiator',
              user: {
                email: this.$auth.user.email,
                avatar: this.$auth.user.avatar,
                username: this.$auth.user.username
              }
            }
          ]
        }

        const attachments = []
        previewOffer.attachments = []

        for (let i = 0; i < this.previews.length; i++) {
          attachments.push({
            type: this.files[i].name.slice(-3),
            key: '332/1574268372485 - ' + this.files[i].name,
            url: this.previews[i],
            thumbnailUrl: this.previews[i]
          })
        }

        if (this.offer && this.offer.attachments.length) {
          previewOffer.attachments = this.offer.attachments
        }

        if (attachments.length) {
          previewOffer.attachments = previewOffer.attachments.concat(
            attachments
          )
        }

        if (this.timeLimit) {
          previewOffer.dueDate = this.date
        }

        if (this.money) {
          previewOffer.amount = this.amount
          previewOffer.currency = this.currency
          previewOffer.payedViaGetRealLab = this.automaticPayment
        }

        if (this.dealFor === '2') {
          previewOffer.parties.push({
            status: 'pending',
            partyRole: 'participant',
            contactDetailUsed: this.firstSide.val,
            user: this.userData(this.firstSide)
          })
          previewOffer.parties.push({
            status: 'pending',
            partyRole: 'participant',
            contactDetailUsed: this.secondSide.val,
            user: this.userData(this.secondSide)
          })
        } else {
          previewOffer.parties.push({
            status: 'pending',
            partyRole: 'participant',
            contactDetailUsed: this.firstSide.val,
            user: this.userData(this.firstSide)
          })
        }
        if (this.payer === 'Me') {
          previewOffer.parties[0].payer = true
          previewOffer.parties[1].payer = false
          if (previewOffer.parties[2]) {
            previewOffer.parties[2].payer = false
          }
        } else if (
          this.payer === this.firstSide.val ||
          this.payer === this.firstSide.user.username
        ) {
          previewOffer.parties[0].payer = false
          previewOffer.parties[1].payer = true
          if (previewOffer.parties[2]) {
            previewOffer.parties[2].payer = false
          }
        } else {
          previewOffer.parties[0].payer = false
          previewOffer.parties[1].payer = false
          previewOffer.parties[2].payer = true
        }

        this.$bus.$emit('open-offer', previewOffer)
      }
    },
    swap() {
      ;[this.recipient, this.payer] = [this.payer, this.recipient]
      this.swapped = !!this.swapped
    },
    updateParties() {
      if (this.swapped && this.money) {
        if (this.dealFor === '2') {
          this.updatePayer(this.secondSide)
        }
        this.updateRecipient(this.firstSide)
      } else {
        this.updatePayer(this.firstSide)
        if (this.dealFor === '2') {
          this.updateRecipient(this.secondSide)
        }
      }
    },
    updateRecipient(side) {
      if (side.isValid) {
        if (side.user.blank) {
          this.recipient = side.val
        } else {
          this.recipient = side.user.username
        }
      }
    },
    updatePayer(side) {
      if (side.isValid) {
        if (this.dealFor === '1' && this.payer === 'Me') {
          this.recipient = 'Me'
        }
        if (side.user.blank) {
          this.payer = side.val
        } else {
          this.payer = side.user.username
        }
      }
    },
    isImage(type) {
      return /(jpe?g|png|gif)$/i.test(type)
    },
    deleteFiles() {
      if (this.deletePreviews.length) {
        this.$offerService.deleteFiles(this.offer.id, this.deletePreviews)
      }
    },
    deleteFile(id, key) {
      const attachment = { Key: key }
      this.deletePreviews.push(attachment)
      this.offer.attachments.splice(id, 1)
    },
    deletePreview(id) {
      this.previews.splice(id, 1)
      this.files.splice(id, 1)
    },
    setSwapped() {
      const payer = this.offer.parties.find((party) => party.payer === true)

      if (payer.user.username === this.$auth.user.username) {
        this.swapped = true
      } else if (
        this.secondSide.val === payer.user.email ||
        this.secondSide.val === payer.user.phoneNumber
      ) {
        this.swapped = true
      }

      if (this.swapped === true && this.dealFor === '1') {
        this.payer = 'Me'
      }
    },
    userData(side) {
      if (!side.user.blank) {
        return side.user
      } else {
        return {
          username: side.val,
          avatar: '/avatar-default.png',
          numberOfDeals: 0,
          rating: 100
        }
      }
    },
    async createStripeIntent(offerId, detail, payAlso = false) {
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      try {
        const stripeToken = await createToken()
        detail.stripeToken = stripeToken
        const stripeCustomerId = this.user.stripeCustomerId
        if (!stripeCustomerId) {
          await this.$userService.changeStripeCustomer({
            stripeToken: stripeToken.token.id,
            email: this.user.email
          })
        } else {
          console.log(stripeCustomerId)
          await this.$userService.updateStripeCustomer({
            stripeToken: stripeToken.token.id,
            customerId: this.user.stripeCustomerId
          })
        }

        if (!payAlso) {
          await this.$paymentService.fetchPaymentIntent(offerId, detail)
        } else {
          await this.$paymentService.fetchPaymentIntentAndPay(offerId, detail)
        }
        console.log('finished')
      } catch (error) {
        console.error(error)
        return error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col-12,
.col-3 {
  padding-right: 16px;
  padding-left: 16px;
}

.v-card__title {
  background: #712fff;
  color: white;
  padding: 30px 0px 30px 16px !important;

  .headline {
    font-family: Oswald !important;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.v-card__text {
  color: black;
  padding: 0 4px 20px !important;
}

.modal-radio::v-deep .mdi-radiobox-blank {
  color: #712fff !important;
  caret-color: #712fff !important;
}

.modal-radio::v-deep label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.title-1 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: black;
}
.title-2 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
.title-3 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: black;
}
.title-4 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-top: 5px;
}
.title-5 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-top: 10px;
}
.title-6 {
  background: #dccdff;
  height: 40px;
  border-bottom: 1px solid #712fff;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #712fff;
  padding: 0px 8px;
}

.purple-lighter-text {
  color: #9c6eff;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
}

.offer-type {
  margin-top: 12px;
}

.v-select {
  margin-top: 4px;
  margin-bottom: 16px;
}
.v-radio {
  margin-right: 0px;
}

.v-card::v-deep .v-messages {
  color: #9c6eff;
}

.v-card::v-deep .mdi-checkbox-blank-outline {
  color: #712fff;
}

.theme--light.v-text-field--filled:not(.v-input--is-focused)
  > .v-input__control
  > .v-input__slot:hover {
  background: #dccdff;
}
.v-card::v-deep .v-select__slot {
  background: #dccdff;
  min-height: 40px;
  height: 40px;
}
.v-card::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  border-color: #712fff;
}
.v-card::v-deep .v-input__control > .v-input__slot:before {
  border-color: #712fff;
}
.v-card::v-deep
  .v-text-field
  > .v-input__control
  > .v-input__slot:hover:before {
  border-color: #712fff;
}
.v-card::v-deep .v-select i {
  color: #712fff !important;
}
.v-card::v-deep .v-select .v-select__selections {
  color: #712fff;
}
.v-card::v-deep .v-select .v-label {
  color: #712fff;
}

.v-card::v-deep .v-text-field--filled > .v-input__control > .v-input__slot {
  background: #dccdff;
  border-radius: 0px;
  min-height: 40px;
}

.v-card::v-deep
  .v-text-field--filled
  > .v-input__control
  > .v-input__slot:before {
  border-color: #712fff;
}

.v-card::v-deep
  .v-text-field--filled
  > .v-input__control
  > .v-input__slot:after {
  border-color: #712fff;
}

.v-card::v-deep
  .v-text-field--filled
  > .v-input__control
  > .v-input__slot:hover {
  background: #dccdff;
}

.v-card::v-deep
  .v-text-field--filled
  > .v-input__control
  > .v-input__slot:hover:before {
  border-color: #712fff;
}

.v-card::v-deep .v-text-field--filled .v-input__slot input {
  margin-top: 4px;
}

.v-card::v-deep .v-text-field--filled .theme--light.v-label {
  color: #712fff;
  top: 10px;
}

.v-card::v-deep textarea {
  margin-top: 5px !important;
}

.v-card__text {
  padding: 0 0 20px !important;
}

hr {
  border-color: #dccdff !important;
}

.v-card__actions {
  padding: 12px;
}

.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.v-input--checkbox {
  margin-bottom: 20px;
}

.close-button {
  margin-right: 18px;
}
.v-tooltip__content {
  pointer-events: auto;
}

.cancel-modal {
  .v-card__title {
    background: white;
    color: #712fff;
    padding: 12px 0px 12px 16px !important;
    border-bottom: 1px solid #dccdff;
    font-family: Oswald !important;
    font-style: normal;
    font-weight: normal;
    font-size: 20px !important;
    line-height: 20px;
  }
  .close-confirmation {
    margin-right: 12px;
    cursor: pointer;
  }
  .v-card__text {
    padding: 20px 16px 5px 16px !important;
  }
  .v-btn {
    color: #712fff;
  }
}

.offer-select {
  padding-left: 0px;
}
.hint {
  color: #9c6eff;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 16px;
  margin-left: 12px;
  margin-top: 4px;
}

.date-field {
  max-width: 135px;
  margin-top: 0px;
  padding-top: 0px;
  margin-left: 10px;
}

.date-field::v-deep .v-input__slot {
  width: 90px;
}

.inline-flex {
  display: inline-flex;
}
.filename {
  background-color: #210759;
  opacity: 0.6;
  margin-bottom: 28px;
}

.filename .body-1 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 17px;
  float: left;
}
.v-card::v-deep .file .v-image__image {
  width: 120px;
  height: 120px;
  left: 50%;
  margin-left: -60px;
}
.amount {
  width: 70px;
  margin-left: 8px;
}
.currency {
  width: 100px;
  margin-top: 0px;
  margin-left: 9px;
}
.amount-container {
  height: 48px;
}
.pay-text {
  margin-left: 8px;
}

.rtl-layout {
  .close-button {
    padding-left: 16px;
  }
  .offer-select {
    padding-right: 0px;
    padding-left: 16px;
  }
  .v-overflow-btn::v-deep .v-label {
    margin-right: 16px;
  }
  .v-overflow-btn::v-deep .v-select__selection--comma:first-child {
    margin-right: 16px;
    margin-left: 4px;
  }
  .close-confirmation {
    margin-left: 12px;
  }
  .pay-text {
    margin-right: 8px;
    margin-left: 0px;
  }
  .stripe-card {
    width: 300px;
    border: 1px solid grey;
  }
  .stripe-card.complete {
    border-color: green;
  }
}
</style>
