export default ($axios) => ({
  async authorizePaymentIntent(offerId) {
    const url = '/api/payments/' + offerId + '/authorizePaymentIntent'
    const request = await $axios.post(url)

    return request
  },

  async salePaymentIntent(offerId) {
    const url = '/api/payments/' + offerId + '/salePaymentIntent'
    const request = await $axios.post(url)

    return request
  },

  async addPaymentDetails(offerId, PayerID, token, paymentId) {
    const url = `/api/payments/${offerId}/addPaymentDetails?PayerID=${PayerID}&token=${token}&paymentId=${paymentId}`

    const payment = await $axios.post(url)
    return payment
  },

  async getPayPalId() {
    const payPalId = await $axios.get(`/api/users/payPalId`)
    return payPalId.data.payPalId
  },

  async updatePayPalId(payPalId) {
    const url = `/api/users/payment/${payPalId}`
    await $axios.post(url)
  },

  async feePercentage() {
    const request = await $axios.get('/api/payments/feePercentage')
    return request
  },

  async fetchPaymentIntent(offerId, offer) {
    const request = await $axios.post(
      `/api/payments/${offerId}/create-payment-intent`,
      offer
    )
    return request
  },

  async fetchPaymentIntentAndPay(offerId, offer, stripeToken) {
    const request = await $axios.post(
      `/api/payments/${offerId}/create-payment-intent-and-pay`,
      offer
    )
    return request
  }
})
