<template>
  <v-dialog v-model="show" persistent max-width="480px" content-class="mx-0">
    <v-card>
      <v-card-title>
        <v-icon
          v-if="!permanent"
          @click="show = false"
          size="30"
          color="white"
          class="close-button pointer"
          >mdi-close</v-icon
        >
        <span class="headline">{{ $t('loginModal.login') }}</span>
      </v-card-title>
      <v-card-text>
        <form @submit.prevent="login">
          <div v-if="redirect" class="not-authorized">
            {{ $t('loginModal.notAuthorized') }}
          </div>
          <Notification v-if="error" :message="error" />
          <v-text-field
            v-model="email"
            filled
            single-line
            type="text"
            label="email"
          />
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            filled
            single-line
            label="password"
          >
            <template v-slot:append>
              <v-icon @click="showPassword = !showPassword" color="#712fff">{{
                showPassword ? 'mdi-eye-off' : 'mdi-eye'
              }}</v-icon>
            </template>
          </v-text-field>
          <a @click.prevent="forgetPassword" href="#">
            {{ $t('loginModal.forgotPassword') }}
          </a>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="!permanent" @click="show = false" color="#712fff" text>
          {{ $t('close') }}
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-btn @click="register" color="#712fff" text>
          {{ $t('loginModal.signup') }}
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-btn @click="login" color="#712fff" text>
          {{ $t('loginModal.login') }}
        </v-btn>
      </v-card-actions>
      <confirm ref="confirm"></confirm>
    </v-card>
  </v-dialog>
</template>

<script>
import Notification from '@/components/Notification'
import Confirm from '@/components/modals/Confirm'

export default {
  components: {
    Notification,
    Confirm
  },
  props: {
    value: Boolean,
    redirect: Boolean,
    permanent: Boolean
  },
  data() {
    return {
      email: '',
      password: '',
      error: null,
      showPassword: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async login() {
      try {
        await this.$auth.loginWith('local', {
          data: {
            email: this.email,
            password: this.password
          }
        })
        this.$toast.success('Logged In!', { duration: 2000 })
        this.$router.push(this.localePath({ path: '/dashboard' }))
      } catch (e) {
        if (e.response) {
          this.error = e.response.data.error
        }
      }
    },
    forgetPassword() {
      this.$bus.$emit('forget-password')
    },
    register() {
      this.$router.push(this.localePath({ path: '/register' }))
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  background: #712fff;
  color: white;
  padding: 30px 0px 30px 16px !important;

  .headline {
    font-family: Oswald !important;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.v-card__actions {
  padding: 12px;
}

.v-card__text {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
}

.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.close-button {
  margin-right: 18px;
}

.v-card::v-deep .v-messages {
  color: #9c6eff;
}

.theme--light.v-text-field--filled:not(.v-input--is-focused)
  > .v-input__control
  > .v-input__slot:hover {
  background: #dccdff;
}
.v-card::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  border-color: #712fff;
}
.v-card::v-deep
  .v-text-field
  > .v-input__control
  > .v-input__slot:hover:before {
  border-color: #712fff;
}
.v-card::v-deep .v-text-field--filled > .v-input__control > .v-input__slot {
  background: #dccdff;
  border-radius: 0px;
  min-height: 40px;
}
.v-card::v-deep
  .v-text-field--filled
  > .v-input__control
  > .v-input__slot:before {
  border-color: #712fff;
}

.v-card::v-deep
  .v-text-field--filled
  > .v-input__control
  > .v-input__slot:after {
  border-color: #712fff;
}

.v-card::v-deep
  .v-text-field--filled
  > .v-input__control
  > .v-input__slot:hover {
  background: #dccdff;
}

.v-card::v-deep
  .v-text-field--filled
  > .v-input__control
  > .v-input__slot:hover:before {
  border-color: #712fff;
}

.v-card::v-deep .v-text-field--filled .v-input__slot input {
  margin-top: 4px;
}

.v-card::v-deep .v-text-field--filled .theme--light.v-label {
  color: #712fff;
  top: 10px;
}
a {
  color: #9c6eff;
  text-decoration: none;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
.not-authorized {
  color: red;
  margin-bottom: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
.v-card::v-deep .v-input__append-inner {
  margin-top: 10px;
}
</style>
