<template>
  <v-card class="incoming-offer">
    <AcceptedModal ref="acceptedModal" />
    <NotifyNoOffersModal
      v-model="showNotifyNoOffersModal"
      :offer="returnedOffer"
    />
    <SelectPartner ref="selectPartner"></SelectPartner>
    <PaymentModal ref="paymentModal" :key="key" />
    <CreditCardModal ref="creditCardModal" />
    <v-list-item v-if="needFeedback" class="feedback">
      <v-list-item-content>
        <div class="upper-question">{{ $t('incomingOffer.feedback') }}</div>
        <div v-if="timeLeft" class="time-left">
          {{ $t('incomingOffer.timeLeft') }} {{ timeLeft }}
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="needFeedback" class="action pt-4 pb-2">
      <v-btn
        @click="sendFeedback('yes')"
        rounded
        block
        color="white"
        elevation="0"
        >{{ $t('yes') }}</v-btn
      >
    </v-list-item>
    <v-list-item v-if="needFeedback" class="action">
      <v-btn
        @click="sendFeedback('no')"
        rounded
        block
        color="white"
        elevation="0"
        >{{ $t('no') }}</v-btn
      >
    </v-list-item>
    <v-list-item v-if="needFeedback" class="action pb-4 pt-2">
      <v-btn
        @click="sendFeedback('MuttuallyCancelled')"
        rounded
        block
        color="white"
        elevation="0"
        >{{ $t('mutuallyCanceled') }}</v-btn
      >
    </v-list-item>
    <v-list-item v-if="less24hrs" class="feedback">
      <v-list-item-content>
        <div class="upper-question">{{ $t('incomingOffer.less24h') }}</div>
        <div class="time-left">
          {{ $t('incomingOffer.timeLeft') }} {{ dueDate }}
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      :class="{ rounded: !less24hrs && !needFeedback }"
      class="card-header no-radius white--text theme--dark pt-1"
    >
      <v-list-item-avatar v-if="!isAnonymous">
        <img :src="initiator.avatar" />
      </v-list-item-avatar>
      <v-list-item-avatar v-else>
        <v-icon size="46" color="black">mdi-account-circle</v-icon>
      </v-list-item-avatar>
      <v-list-item-content v-if="!isAnonymous">
        <div
          v-if="initiator.username !== $auth.user.username"
          class="card-title"
        >
          {{ initiator.username }}:
        </div>
        <div v-else class="card-title">{{ $t('fromYou') }}:</div>
        <div class="pt-2 pb-1 score">
          <span :class="color(initiator.reputationAsInitiator)"
            >{{ initiator.reputationAsInitiator }}%</span
          >
          {{ $t('offer.initiatorScoreText') }}
          {{ initiator.initiatorOffesSent }})
        </div>
      </v-list-item-content>
      <v-list-item-content v-else>
        <div class="card-title">{{ $t('incomingOffer.anonym') }}</div>
        <div class="pt-2 pb-1 score">
          <span :class="color(anonymousInitiatorParty.reputationAsInitiator)"
            >{{ anonymousInitiatorParty.reputationAsInitiator }}%</span
          >
          {{ $t('offer.initiatorScoreText') }}
        </div>
      </v-list-item-content>
      <v-menu
        :offset-y="true"
        :right="rightLayout"
        :left="!rightLayout"
        bottom
        class="card-menu"
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon class="mb-7">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click.prevent="block">
            <v-list-item-title>
              <v-icon color="#712FFF" class="menu-icon">mdi-cancel</v-icon>
              {{ $t('incomingOffer.block') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click.prevent="block">
            <v-list-item-title>
              <v-icon color="#712FFF" class="menu-icon"
                >mdi-share-variant</v-icon
              >
              {{ $t('incomingOffer.share') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>

    <v-list-item v-if="offer.parties.length === 3" class="card-sides">
      <v-row class="text-center" style="max-width: 303px;">
        <v-col cols="6" class="pt-0 pb-2">
          <icon-arrow class="icon-arrow" />
          <v-list-item-content class="pt-0 pb-0">
            <div class="side-title">
              {{ participants[0].username }}
              <span
                :class="color(participants[0].reputationAsParticipant)"
                class="score"
                >{{ participants[0].reputationAsParticipant }}%</span
              >
            </div>
          </v-list-item-content>
        </v-col>
        <v-col cols="6" class="pt-0 pb-2">
          <icon-arrow class="icon-arrow" />
          <v-list-item-content class="pt-0 pb-0">
            <div class="side-title">{{ $t('withYou') }}</div>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list-item>
    <v-list-item v-else class="card-sides">
      <v-row class="text-center">
        <v-col cols="4" class="pt-0 pb-2">
          <icon-arrow class="icon-arrow" />
          <v-list-item-content class="pt-0 pb-0">
            <div class="side-title">{{ $t('withYou') }}</div>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list-item>

    <v-list-item v-if="offer.parties.length === 3" class="card-sides">
      <v-row class="text-center">
        <v-col cols="4" offset="1" class="pt-0 pb-0">
          <v-list-item-avatar v-if="participants[0].avatar" class="pt-0 mr-0">
            <img :src="participants[0].avatar" />
          </v-list-item-avatar>
          <v-list-item-avatar v-else class="pt-0 mr-0">
            <v-icon size="46" color="black">mdi-account-circle</v-icon>
          </v-list-item-avatar>
        </v-col>
        <v-col cols="2" class="ampersand-spacer pb-0">&</v-col>
        <v-col cols="4" class="pt-0">
          <v-list-item-avatar
            v-if="participants[1].avatar"
            class="pt-0 pb-0 mr-0"
          >
            <img :src="participants[1].avatar" />
          </v-list-item-avatar>
          <v-list-item-avatar v-else class="pt-0 pb-0 mr-0">
            <v-icon size="46" color="black">mdi-account-circle</v-icon>
          </v-list-item-avatar>
        </v-col>
      </v-row>
    </v-list-item>

    <v-list-item v-else class="card-sides">
      <v-row class="text-center">
        <v-col cols="4" class="pt-0 pb-0">
          <v-list-item-avatar class="pt-0 mr-0">
            <img :src="participants.avatar" />
          </v-list-item-avatar>
        </v-col>
      </v-row>
    </v-list-item>

    <v-card-text class="deal-title">“{{ offer.name }}”</v-card-text>

    <v-divider v-if="offer.description" class="mx-4"></v-divider>

    <v-card-text v-if="offer.description" class="deal-description">
      {{ description }}
    </v-card-text>

    <v-card-text
      v-if="continueReading"
      @click="continueReading = false"
      class="continue-reading pointer py-0"
    >
      {{ $t('incomingOffer.continue') }}
      <v-icon color="#9c6eff">mdi-chevron-right</v-icon>
    </v-card-text>

    <v-divider v-if="offer.attachments.length" class="mx-4"></v-divider>

    <v-list-item v-if="offer.dueDate">
      <v-row>
        <v-col cols="2" class="text-center py-0">
          <v-icon size="24" color="#712FFF">mdi-timer</v-icon>
        </v-col>
        <v-col cols="10" class="px-0 py-0">
          <v-list-item-content class="deal-condition pt-0">
            <div>
              {{ $t('offer.limited') }}
              <div class="purple-text inline">{{ dueDate }}</div>
            </div>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list-item>

    <v-list-item v-if="offer.amount > 0">
      <v-row>
        <v-col cols="2" class="text-center py-0">
          <v-icon size="24" color="#712FFF">mdi-currency-usd</v-icon>
        </v-col>
        <v-col cols="10" class="px-0 py-0">
          <v-list-item-content class="deal-condition pt-0">
            <div>{{ offer.amount }} {{ offer.currency }}</div>
            <div>
              <div class="bold inline">
                {{ $t('incomingOffer.paidBy') }} {{ payer }}
              </div>
              {{ $t('incomingOffer.to') }} {{ recipient }}
              {{
                offer.payedViaGetRealLab
                  ? '-' + $t('incomingOffer.automaticPayment')
                  : ''
              }}
              <div v-if="offer.payedViaGetRealLab">
                {{ $t('newOfferModal.includingFee') }}
                {{ offer.amountWithFee }}
                {{ offer.currency }}
              </div>
            </div>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list-item>

    <v-divider v-if="offer.attachments.length" class="mx-4"></v-divider>

    <v-list-item v-if="offer.attachments.length">
      <v-row>
        <v-col cols="2" class="text-center pb-0">
          <v-icon size="24" color="#712FFF">mdi-paperclip</v-icon>
        </v-col>
        <v-col cols="9" class="px-0 pb-0">
          <v-list-item-content class="deal-condition pt-0">
            <div class="bold">{{ $t('incomingOffer.attachedFiles') }}</div>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list-item>
    <v-list-item v-if="offer.attachments.length">
      <v-row>
        <v-col
          v-for="(preview, i) in offer.attachments"
          :key="i"
          cols="4"
          class="text-right px-0 py-2"
        >
          <v-img
            v-if="isImage(preview.type)"
            :src="preview.thumbnailUrl"
            @click="open(preview.url)"
            height="76"
            width="82"
          >
            <v-row align="end" class="lightbox white--text fill-height">
              <v-col class="filename py-2 px-1">
                <div class="body-1">{{ fileName(preview.key) }}</div>
              </v-col>
            </v-row>
          </v-img>
          <v-img
            v-else
            @click="open(preview.url)"
            src="/file.png"
            height="76"
            width="82"
          >
            <v-row align="end" class="lightbox white--text fill-height">
              <v-col class="filename py-2 px-1">
                <div class="body-1">{{ fileName(preview.key) }}</div>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
    </v-list-item>

    <v-card-actions
      v-if="!isAccepted && !isTwoPartiesAnonymous && !accaptedByMe"
      class="pt-5"
    >
      <v-btn
        @click="acceptOffer"
        :disabled="offer.review"
        text
        color="deep-purple accent-4"
        >{{ $t('accept') }}</v-btn
      >
      <v-btn
        @click="rejectOffer"
        :disabled="offer.review"
        text
        color="deep-purple accent-4"
        >{{ $t('reject') }}</v-btn
      >
      <v-btn
        v-if="isInitiator"
        @click="deleteOffer"
        :disabled="offer.review"
        text
        color="deep-purple accent-4"
        >{{ $t('delete') }}</v-btn
      >
    </v-card-actions>

    <v-card-actions v-if="isAnonymous && partiesLenght !== 3" class="pt-5">
      <v-btn @click="choosePartner" text color="deep-purple accent-4">{{
        $t('choosePartner')
      }}</v-btn>
      <v-btn @click="rejectAnonOffer" text color="deep-purple accent-4">{{
        $t('reject')
      }}</v-btn>
    </v-card-actions>

    <v-list-item v-if="!needFeedback && accaptedByMe" class="waiting">
      <v-list-item-content>
        <div class="text">{{ $t('incomingOffer.accepted') }}</div>
        <div class="time-left">
          <v-icon color="#9C6EFF">mdi-timer</v-icon>
          <span class="timer">{{ waitingTime }}</span>
        </div>
        <div>
          <v-btn
            @click="rejectOffer"
            text
            color="deep-purple accent-4"
            class="cancel"
            >{{ $t('cancel') }}</v-btn
          >
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import IconArrow from '@/components/icons/IconArrow.vue'
import AcceptedModal from '@/components/modals/AcceptedModal.vue'
import NotifyNoOffersModal from '@/components/modals/NotifyNoOffersModal.vue'
import SelectPartner from '@/components/modals/SelectPartner.vue'
import PaymentModal from '@/components/modals/PaymentModal'
import CreditCardModal from '@/components/modals/CreditCardModal.vue'

export default {
  components: {
    IconArrow,
    AcceptedModal,
    NotifyNoOffersModal,
    SelectPartner,
    PaymentModal,
    CreditCardModal
  },
  props: {
    offer: Object,
    isInitiator: Boolean
  },
  data() {
    return {
      continueReading: true,
      showAcceptedModal: false,
      username: this.$auth.user.username,
      showNotifyNoOffersModal: false,
      returnedOffer: {},
      key: 0,
      user: {}
    }
  },
  computed: {
    isAnonymous() {
      return this.offer.type === 'Anonymous'
    },
    isTwoPartiesAnonymous() {
      return (
        this.offer.type === 'Anonymous' &&
        this.participantsParties().length === 1
      )
    },
    isAccepted() {
      return this.offer.status === 'accepted'
    },
    accaptedByMe() {
      if (this.participantsParties().length === 2) {
        const me = this.offer.parties.find(
          (party) =>
            party.user !== undefined &&
            party.user.username === this.$auth.user.username &&
            party.partyRole === 'participant'
        )
        return me.status === 'accepted'
      }
      return false
    },
    rightLayout() {
      return this.$i18n.locale === 'he'
    },
    participants() {
      const party = this.participantsParties()

      if (party.length === 2) {
        if (this.offer.type !== 'Anonymous') {
          const participant =
            party[0].user.username === this.$auth.user.username
              ? [party[1].user, party[0].user]
              : [party[0].user, party[1].user]

          return participant
        } else {
          if (party[0].user && party[1].user) {
            return [party[1].user, party[0].user]
          }
          const participant =
            party[0].username === 'Anonymous'
              ? [{ username: 'Anonymous' }, party[1].user]
              : [{ username: 'Anonymous' }, party[0].user]

          return participant
        }
      }

      return party[0].user
    },
    initiator() {
      const party = this.offer.parties.find(
        (party) => party.partyRole === 'initiator'
      )

      return party.user
    },
    partiesLenght() {
      return this.offer.parties.length
    },
    anonymousInitiatorParty() {
      const party = this.offer.parties.find(
        (party) => party.partyRole === 'initiator'
      )

      return party
    },
    description() {
      if (this.offer.description.length > 200 && this.continueReading) {
        return this.offer.description.substring(0, 197) + '...'
      }

      return this.offer.description
    },
    needFeedback() {
      const party = this.participantsParties()
      if (
        party.length === 2 &&
        (party[0].status !== 'accepted' || party[1].status !== 'accepted')
      ) {
        return false
      }
      return this.isAccepted
    },
    waitingTime() {
      return this.duration(this.offer.updatedAt)
    },
    timeLeft() {
      if (!this.isAnonymous) {
        const party = this.offer.parties.find(
          (party) => party.user.username === this.$auth.user.username
        )

        if (party.feedbackDueDate) {
          return this.duration(party.feedbackDueDate, 'upcoming')
        }
      }

      return false
    },
    payer() {
      return this.paymentParty(true)
    },
    recipient() {
      if (this.offer.parties.length === 2) {
        return this.paymentParty(false)
      } else {
        const payer = this.offer.parties.find(function(party) {
          return party.payer === false && party.partyRole === 'participant'
        })

        return payer.user.username === this.$auth.user.username
          ? 'you'
          : payer.user.username
      }
    },
    less24hrs() {
      if (this.offer.dueDate) {
        const now = this.$moment.utc()
        const dueDate = this.$moment(this.offer.dueDate)
        const diff = dueDate.diff(now)
        const dayInMs = 86400000
        if (diff < dayInMs) {
          return true
        }
      }
      return false
    },
    dueDate() {
      const now = this.$moment()
      const dueDate = this.$moment(this.offer.dueDate)
      const diff = dueDate.diff(now)
      const duration = this.$moment.duration(diff)
      const dueDateFormat =
        Math.floor(duration.asHours()) +
        this.$t('h') +
        this.$moment.utc(diff).format(':mm') +
        this.$t('m')

      return dueDateFormat
    }
  },
  async mounted() {
    const user = await this.$userService.me()
    this.user = user.user
    if (this.offer.description.length <= 200) {
      this.continueReading = false
    }
  },
  methods: {
    async acceptOffer() {
      const text =
        this.offer.parties.length === 3
          ? this.$t('confirm.areYouSure3rd')
          : `${this.$t('confirm.areYouSureAgree')} ${
              this.initiator.username
            } ${this.$t('confirm.willBeNotified')}`

      const confirm = await this.$root.$accept.open(this.$t('accept'), text)

      if (confirm) {
        if (
          this.offer.amount &&
          this.offer.payedViaGetRealLab &&
          this.paymentParty(true) === 'you'
        ) {
          try {
            // let payment = await this.$paymentService.salePaymentIntent(
            //   this.offer.id
            // )
            //
            // const paymentId = payment.data.links
            //   .find((link) => link.rel === 'approval_url')
            //   .href.slice(-20)
            // const paymentDetails = await this.$refs.paymentModal.open(paymentId)
            // this.key++
            //
            // if (!paymentDetails) {
            //   return
            // }
            //
            // payment = await this.$paymentService.addPaymentDetails(
            //   this.offer.id,
            //   paymentDetails.payerID,
            //   paymentDetails.orderID,
            //   paymentDetails.paymentID
            // )
            // if (this.user.stripeCustomerId) {
            //   await this.$paymentService.fetchPaymentIntent(this.offer.id, {
            //     amount: this.offer.amountWithFee * 100,
            //     currency: this.offer.currency
            //   })
            // } else {
            await this.$refs.creditCardModal.open(
              this.offer.id,
              {
                amount: this.offer.amountWithFee * 100,
                currency: this.offer.currency
              },
              this.user
            )
            // }
          } catch (e) {
            this.$toast.error(e.response ? e.response.data.errorMessage : e)
            return
          }
        }
        try {
          const acceptedOffer = await this.$offerService.acceptOffer(
            this.offer.id
          )

          await this.$refs.acceptedModal.open(acceptedOffer.data)

          if (!acceptedOffer.data.isDummy) {
            this.$bus.$emit('draft-edited', acceptedOffer.data)
          }
          this.$emit('hide')
        } catch (e) {
          this.$toast.error(e.response.data.errorMessage)
        }
      }
    },
    async rejectOffer() {
      const confirm = await this.$root.$confirm.open(
        this.$t('reject'),
        this.$t('confirm.areYouSure')
      )
      if (confirm) {
        try {
          const rejectedOffer = await this.$offerService.rejectOffer(
            this.offer.id
          )
          this.$toast.success(this.$t('toaster.offerRejected'))
          if (this.$auth.user.username === this.initiator.username) {
            this.$bus.$emit('draft-edited', rejectedOffer.data)
          } else {
            this.$bus.$emit('offer-deleted', rejectedOffer.data.id)
          }
          this.$emit('hide')
          if (rejectedOffer.data.presentAllowNotification) {
            this.returnedOffer = rejectedOffer.data
            this.showNotifyNoOffersModal = true
          }
        } catch (e) {
          this.$toast.error(e.response.data.errorMessage)
        }
      }
    },
    async deleteOffer() {
      const confirm = await this.$root.$confirm.open(
        this.$t('delete'),
        this.$t('confirm.areYouSure'),
        { color: 'red' }
      )

      if (confirm) {
        const request = await this.$offerService.deleteOffer(this.offer.id)
        if (request.status === 200) {
          this.$toast.success(this.$t('toaster.offerDeleted'))
          this.$bus.$emit('offer-deleted', this.offer.id)
          this.$emit('hide')
        }
      }
    },
    async rejectAnonOffer() {
      const confirm = await this.$root.$confirm.open(
        this.$t('reject'),
        this.$t('confirm.areYouSure')
      )
      if (confirm) {
        try {
          await this.$offerService.offerAnonymousAcceptance(this.offer.id, {
            phone: '',
            email: '',
            propagateOffer: false
          })
          this.$toast.success(this.$t('toaster.offerRejected'))
          this.$bus.$emit('offer-deleted', this.offer.id)
          this.$emit('hide')
        } catch (e) {
          this.$toast.error(e.response.data.errorMessage)
        }
      }
    },
    async choosePartner() {
      const selectedPartner = await this.$refs.selectPartner.open()

      if (selectedPartner) {
        try {
          const acceptedOffer = await this.$offerService.offerAnonymousAcceptance(
            this.offer.id,
            selectedPartner
          )

          if (acceptedOffer.data.noMatch) {
            this.$bus.$emit('offer-deleted', this.offer.id)
            this.$toast.info(this.$t('toaster.noMatch'))
          } else if (acceptedOffer.data.status === 'opened') {
            this.$bus.$emit('offer-deleted', this.offer.id)
            this.$bus.$emit('offer-created', acceptedOffer.data)
            this.$toast.info(this.$t('toaster.noMatchPropagate'))
          } else {
            this.$bus.$emit('draft-edited', acceptedOffer.data)
            this.$toast.success(this.$t('toaster.offerAccepted'))

            await this.$refs.acceptedModal.open(acceptedOffer.data)
          }

          // this.$emit('hide')
        } catch (e) {
          this.$toast.error(e.response.data.errorMessage)
        }
      }
    },
    open(url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    fileName(fullName) {
      fullName = fullName.substr(20)
      let name = fullName

      if (fullName.length > 13) {
        const type = fullName.slice(-3)
        name = name.substring(0, 8) + '....' + type
      }

      return name
    },
    isImage(type) {
      return /(jpe?g|png|gif)$/i.test(type)
    },
    color(rating) {
      let color = ''

      if (rating < 50) {
        color = 'red-text'
      } else if (rating < 75) {
        color = 'yellow-text'
      } else {
        color = 'green-text'
      }

      return color
    },
    async sendFeedback(feedback) {
      const confirm = await this.$root.$confirm.open(
        this.$t('confirm.feedback' + feedback),
        this.$t('confirm.areYouSure')
      )
      if (confirm) {
        await this.$offerService.sendFeedback(this.offer.id, feedback)
        this.$bus.$emit('offer-deleted', this.offer.id)
        this.$toast.success(this.$t('toaster.feedbackSent'))
        this.$emit('hide')
      }
    },
    participantsParties() {
      return this.offer.parties.filter(
        (party) => party.partyRole === 'participant'
      )
    },
    duration(date, type) {
      const now = this.$moment()
      const accepted = this.$moment(date)
      let diff = null
      if (type === 'upcoming') {
        diff = accepted.diff(now)
      } else {
        diff = now.diff(accepted)
      }

      const duration = this.$moment.duration(diff)
      const dueDateFormat =
        Math.floor(duration.asHours()) +
        this.$t('h') +
        this.$moment.utc(diff).format(':mm') +
        this.$t('m')

      return dueDateFormat
    },
    paymentParty(isPayer) {
      const payer = this.offer.parties.find((party) => party.payer === isPayer)
      if (payer.user.username === this.$auth.user.username) {
        return 'you'
      }

      return payer.user.username
    },
    block() {
      this.$toast.info(this.$t('incomingOffer.blockText'))
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 2px 25px rgba(0, 0, 0, 0.2),
    0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0px 0px;

  width: 303px;
}

.card-header {
  border-radius: 8px 8px 0px 0px;
  background: #712fff;
}

.incoming-offer {
  border-radius: 8px 8px 0px 0px !important;
}

.feedback {
  border-radius: 8px 8px 0px 0px !important;
}

.no-radius {
  border-radius: 0;
}
.rounded {
  border-radius: 8px 8px 0px 0px;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 8px 8px 0px 0px;
}
.green-text {
  color: #00ff75;
}

.yellow-text {
  color: #fdbe00;
}

.red-text {
  color: #ff4438;
}

.purple-text {
  color: #712fff;
}
.inline {
  display: inline;
}
hr {
  border-color: #dccdff !important;
}

.card-title {
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
}

.card-header.v-list-item {
  padding-right: 0px;

  .v-list-item__avatar {
    margin-right: 12px;
  }
}

.v-list-item {
  padding: 0 20px;
}

.icon-arrow {
  margin-top: -1px;
}

.ampersand-spacer {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 20px;
}

.score {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.card-sides {
  background: #f4f3f7;
}

.side-title {
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  overflow: inherit;
}

.deal-title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000 !important;
}

.deal-description {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000 !important;
}

.deal-condition > * {
  padding-top: 1px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px !important;
}

.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.v-list-item--link {
  min-height: 32px;
  padding: 0 10px;

  .v-list-item__title {
    line-height: 24px;
  }

  .menu-icon {
    margin-right: 10px;
  }
}

.v-responsive.v-image {
  margin: auto;
  cursor: pointer;
}

.filename {
  background-color: #210759;
  opacity: 0.6;
  margin-left: 12px;
}

.filename .body-1 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px !important;
  line-height: 12px;
  float: left;
}
.upper-question {
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 8px;
}
.time-left {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #712fff;
}
.feedback {
  background: #dccdff;
}
.theme--light.v-list-item.action {
  background-color: #dccdff !important;

  button {
    height: 48px;
    color: #712fff;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
  }
}
.continue-reading {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9c6eff !important;
}
.waiting {
  background-color: #dccdff;

  .text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  .time-left {
    padding-top: 10px;
  }
  .timer {
    color: #9c6eff;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    vertical-align: middle;
    padding-left: 15px;
  }
}
.cancel {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  margin-top: 20px;
}
.rtl-layout {
  .offset-1 {
    margin-right: 8.3333333333%;
    margin-left: 0%;
  }

  .card-header {
    padding-left: 0px !important;

    .v-list-item__avatar {
      margin-top: 0px;
      margin-right: 20px;
      margin-left: 12px;
    }
  }

  .v-list-item--link {
    .menu-icon {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}
</style>
