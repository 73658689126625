<template>
  <v-navigation-drawer
    v-model="visible"
    :right="rightLayout"
    clipped
    fixed
    width="240"
    color="#210759"
    dark
  >
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text class="language-btn">
            <country-flag :country="flag(currentLocale.code)" />
            {{ currentLocale.code }}
          </v-btn>
        </template>
        <v-list class="language-changer">
          <v-list-item
            v-for="(locale, index) in availableLocales"
            :key="index"
            @click="switchLanguage(locale.code)"
          >
            <v-list-item-title>
              <country-flag :country="flag(locale.code)" />
              <span>{{ locale.code }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-list v-if="$auth.loggedIn">
      <v-list-item>
        <v-list-item-avatar height="100" width="100">
          <img :src="$auth.user.avatar" @click="goProfile" class="pointer" />
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
    <v-list-item v-if="$auth.loggedIn">
      <v-list-item-content class="fullname">
        <v-list-item-title
          @click="goProfile"
          class="text-center text-1 pointer"
          >{{ $auth.user.username }}</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>

    <v-list v-if="$auth.loggedIn">
      <v-list-item
        :to="localePath({ path: '/dashboard' })"
        @click="visible = false"
        router
        exact
        class="pl-8"
      >
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.home')" class="text-2" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/profile' })"
        @click="visible = false"
        router
        exact
        class="pl-8"
      >
        <v-list-item-action>
          <v-icon>mdi-account-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.myProfile')" class="text-2" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/deals' })"
        @click="visible = false"
        router
        exact
        class="pl-8"
      >
        <v-list-item-action>
          <v-icon>mdi-history</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.myDeals')" class="text-2" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else class="auth">
      <v-list-item @click="login" class="pl-8">
        <v-list-item-action>
          <v-icon>mdi-location-enter</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="$t('login')" class="text-2" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/register' })"
        @click="visible = false"
        router
        exact
        class="pl-8"
      >
        <v-list-item-action>
          <v-icon>mdi-account-plus-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="$t('register.title')" class="text-2" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list>
      <v-list-item
        :to="localePath({ path: '/about' })"
        router
        exact
        class="pl-8"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.about')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/contact' })"
        router
        exact
        class="pl-8"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.contact')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/terms' })"
        router
        exact
        class="pl-8"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.terms')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="localePath({ path: '/privacy' })"
        router
        exact
        class="pl-8"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('sideMenu.privacy')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list v-if="$auth.loggedIn">
      <v-list-item @click="logout" class="pl-8">
        <v-list-item-action>
          <v-icon>mdi-power</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="$t('navbar.logout')" class="text-1" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import CountryFlag from 'vue-country-flag'
export default {
  components: {
    CountryFlag
  },
  props: {
    rightLayout: Boolean,
    drawer: Boolean
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
    currentLocale() {
      const locale = this.$i18n.locales.find(
        (i) => i.code === this.$i18n.locale
      )

      return locale
    }
  },
  watch: {
    visible(visible) {
      this.$emit('update', visible)
    },
    drawer(drawer) {
      this.visible = drawer
    }
  },
  mounted() {
    this.visible = this.drawer
  },
  methods: {
    async logout() {
      await this.$auth.logout()
    },
    login() {
      this.$emit('open-login')
    },
    switchLanguage(lang) {
      this.$i18n.setLocaleCookie(lang)
      this.$router.push(this.switchLocalePath(lang))
      this.$vuetify.rtl = lang === 'he'
    },
    flag(code) {
      switch (code) {
        case 'en':
          code = 'gb'
          break
        case 'he':
          code = 'il'
          break
      }

      return code
    },
    goProfile() {
      this.$router.push(this.localePath({ path: '/profile' }))
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer::v-deep .v-navigation-drawer__content {
  @include breakpoint(lg) {
    margin-top: 71px;
  }
}

.v-list-item__avatar {
  left: 50%;
  margin-left: -50px;
  margin-right: 0px !important;
  margin-top: 24px;

  img {
    border: 1px solid #712fff;
  }
}
.text-center {
  text-align: center;
}
.text-1 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}
.text-2 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
.v-divider {
  border-color: #712fff !important;
  opacity: 0.3;
  margin-bottom: 40px;
  margin-top: 40px;
}
.v-list-item__action {
  margin-right: 20px !important;
}
.fullname {
  padding-top: 0px;
  padding-bottom: 40px;
}
.auth {
  margin-top: 40px;
}
.language-changer {
  text-transform: uppercase;
  padding: 4px 0;
  .v-list-item--link {
    min-height: 32px;
    padding: 0 10px;
    .v-list-item__title {
      display: inline-block;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.language-btn {
  margin-top: 10px;
}
.flag {
  margin-right: -8px !important;
}

.rtl-layout {
  .v-list-item__avatar {
    right: 50%;
    margin-right: -50px !important;
  }
}
</style>
