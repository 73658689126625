<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <vue-tel-input
        ref="phoneField"
        v-model="input"
        :enabledFlags="isPhone"
        :placeholder="title"
        :maxLen="40"
        @close="closeFlags"
        @input="phoneInput"
      ></vue-tel-input>
      <div class="hint">{{ $t('newOfferModal.emailOrPhone') }}</div>
    </v-col>
    <v-col v-if="user && user.avatar" cols="2" class="py-0 text-center">
      <v-list-item-avatar class="mr-0">
        <img :src="user.avatar" />
      </v-list-item-avatar>
    </v-col>
    <v-col
      v-if="user && user.username"
      cols="10"
      class="py-0 pl-0"
      align-self="end"
    >
      <v-list-item-content class="pt-2">
        <div class="side-title">{{ user.username }}:</div>
        <div class="pt-2 pb-1 score">
          <span class="red-text">{{ user.reputationAsParticipant }}%</span>
          {{ $t('offer.score') }} {{ user.participantOfferAccepted }})
        </div>
      </v-list-item-content>
    </v-col>
    <v-col v-else-if="user && user.blank" cols="12">{{ $t('noUser') }}</v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: Object,
    title: String
  },
  data() {
    return {
      isPhone: false,
      input: '',
      phone: {},
      user: {},
      withRule: [(value) => !!value || this.$t('required')]
    }
  },
  watch: {
    async input(val) {
      if (!val) {
        this.isPhone = false
        this.user = {}
        this.input = ''
      } else {
        val = val.replace(/ /g, '')
        this.isPhone =
          !isNaN(val) ||
          (val.length === 1 && val.charAt(0) === '+') ||
          !!this.phone.isValid
      }
      this.isPhone
        ? await this.getUserByPhone(val)
        : this.getUserByEmailDebounced(val)
    },
    value(val) {
      this.input = val.val

      if (val.initIsValid && val.val.charAt(0) === '+') {
        this.phone.isValid = true
        this.phone.number = { e164: val.val }
      }
    }
  },
  mounted() {
    this.$refs.phoneField.$el.firstElementChild.type = 'text'
  },
  methods: {
    phoneInput(str, phone) {
      this.phone = phone
    },
    closeFlags() {
      this.$refs.phoneField.focus()
    },
    getUserByEmailDebounced(email) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.getUserByEmail(email)
      }, 500) /* 500ms throttle */
    },
    async getUserByEmail(email) {
      const re = /\S+@\S+\.\S+/
      if (re.test(email)) {
        let user = await this.$userService.getUserByEmail(email)

        if (!user) {
          user = { blank: true }
        }
        this.$emit('input', {
          val: email,
          isPhone: false,
          isValid: true,
          user
        })
        this.user = user
      } else {
        this.$emit('input', {
          val: email,
          isPhone: false,
          isValid: false
        })
        this.user = {}
      }
    },
    async getUserByPhone(phone) {
      if (this.phone.isValid) {
        let user = await this.$userService.getUserByPhone(
          this.phone.number.e164
        )
        if (!user) {
          user = { blank: true }
        }
        this.$emit('input', {
          val: this.phone.number.e164,
          isPhone: true,
          isValid: true,
          user
        })
        this.user = user
      } else {
        this.$emit('input', {
          val: phone,
          isPhone: true,
          isValid: false
        })
        this.user = {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.side-title {
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: black;
}
.score {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: black;
}
.vue-tel-input {
  direction: rtl;
  border: 0px;
  background: #dccdff;
  border-radius: 0px;
  min-height: 40px;
  border-bottom: 1px solid #712fff;
}

.vue-tel-input::v-deep .vti__dropdown-list {
  direction: ltr;
  width: 441px;
  left: -385px;
  top: 40px;
  padding-left: 0px;
  color: black;
  strong {
    font-weight: 500;
  }
}
.vue-tel-input::v-deep input {
  direction: ltr;
  padding-left: 12px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.vue-tel-input::v-deep .vti__selection {
  direction: ltr;
}

.vue-tel-input:focus-within {
  border-bottom: 2px solid #712fff;
  box-shadow: none;
}

.vue-tel-input::v-deep .vti__dropdown:hover {
  background-color: #dccdff;
}

.vue-tel-input::v-deep .vti__dropdown.open {
  background-color: #dccdff;
}
.vue-tel-input::v-deep .vti__input::placeholder {
  color: #712fff;
}
.hint {
  color: #9c6eff;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 16px;
  margin-left: 12px;
  margin-top: 4px;
}
</style>
