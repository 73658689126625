<template>
  <v-card class="incoming-offer">
    <v-list-item v-if="needFeedback" class="feedback">
      <v-list-item-content>
        <div class="upper-question">{{ $t('incomingOffer.feedback') }}</div>
        <div v-if="timeLeft" class="time-left">
          {{ $t('incomingOffer.timeLeft') }} {{ timeLeft }}
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="needFeedback" class="action pt-4 pb-2">
      <v-btn
        @click="sendFeedback('yes')"
        rounded
        block
        color="white"
        elevation="0"
        >{{ $t('yes') }}</v-btn
      >
    </v-list-item>
    <v-list-item v-if="needFeedback" class="action">
      <v-btn
        @click="sendFeedback('no')"
        rounded
        block
        color="white"
        elevation="0"
        >{{ $t('no') }}</v-btn
      >
    </v-list-item>
    <v-list-item v-if="needFeedback" class="action pb-4 pt-2">
      <v-btn
        @click="sendFeedback('MuttuallyCancelled')"
        rounded
        block
        color="white"
        elevation="0"
        >{{ $t('mutuallyCanceled') }}</v-btn
      >
    </v-list-item>
    <div :class="{ oppacity: isDraft }">
      <v-list-item
        :class="{ dummy: offer.isDummy, rounded: !needFeedback }"
        class="card-header white--text theme--dark pt-1 pr-0"
      >
        <v-list-item-avatar class="mr-3">
          <img :src="initiator.avatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="card-title">{{
            isAnonymous ? $t('youAnonymous') : $t('fromYou')
          }}</v-list-item-title>
        </v-list-item-content>
        <div
          v-if="offer.isDummy"
          style="color:#fdbe00;"
          class="offer-type dummy-offer"
        >
          {{ $t('offer.dummy') }}
        </div>
        <div
          v-else-if="!isAnonymous"
          style="color:#F4F3F7;"
          class="offer-type real-offer"
        >
          {{ $t('offer.real') }}
        </div>
      </v-list-item>

      <v-list-item v-if="offer.parties.length === 3" class="card-sides">
        <v-row>
          <v-col cols="6" class="pt-0 pb-2 text-center" style="z-index: 2">
            <icon-arrow
              v-if="offer.isDummy"
              class="icon-arrow"
              style="background: #f4f3f7;"
              icon-color="#FDBE00"
            />
            <icon-arrow
              v-else
              class="icon-arrow"
              style="background: #f4f3f7;"
              iconColor="#712FFF"
            />
            <v-list-item-content class="pt-0 pb-0">
              <div class="side-title">
                {{ participants[0].user.username }}
                <span :class="color(participantsScore[0])" class="score"
                  >{{ participantsScore[0] }}%</span
                >
              </div>
            </v-list-item-content>
          </v-col>
          <v-col cols="6" class="pt-0 pb-2 text-center" style="z-index: 2">
            <icon-arrow
              v-if="offer.isDummy"
              class="icon-arrow"
              style="background: #f4f3f7;"
              icon-color="#FDBE00"
            />
            <icon-arrow
              v-else
              class="icon-arrow"
              style="background: #f4f3f7;"
              iconColor="#712FFF"
            />
            <v-list-item-content class="pt-0 pb-0">
              <div class="side-title">
                {{ participants[1].user.username }}
                <span :class="color(participantsScore[1])" class="score"
                  >{{ participantsScore[1] }}%</span
                >
              </div>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item v-if="offer.parties.length === 3" class="card-sides">
        <v-row class="text-center" style="max-width: 303px;">
          <v-col cols="4" offset="1" class="pt-0 pb-0">
            <v-list-item-avatar class="pt-0 mr-0">
              <img :src="participants[0].user.avatar" />
            </v-list-item-avatar>
          </v-col>
          <v-col cols="2" class="ampersand-spacer pb-0">&</v-col>
          <v-col cols="4" class="pt-0">
            <v-list-item-avatar class="pt-0 pb-0 mr-0">
              <img :src="participants[1].user.avatar" />
            </v-list-item-avatar>
          </v-col>
        </v-row>
      </v-list-item>

      <v-list-item v-else class="card-sides">
        <v-row>
          <v-col cols="3" class="pt-0 pb-2 text-center" style="z-index: 2">
            <icon-arrow
              v-if="offer.isDummy"
              class="icon-arrow"
              style="background: #f4f3f7;"
              icon-color="#FDBE00"
            />
            <icon-arrow
              v-else
              class="icon-arrow"
              style="background: #f4f3f7;"
              iconColor="#712FFF"
            />
            <v-list-item-avatar class="pt-0 ml-0">
              <img :src="participants[0].user.avatar" />
            </v-list-item-avatar>
          </v-col>
          <v-col cols="9" class="pl-0 pb-1" align-self="end">
            <v-list-item-content>
              <div class="side-title">{{ participants[0].user.username }}:</div>
              <div class="pt-2 pb-1 score">
                <span :class="color(participantsScore[0])"
                  >{{ participantsScore[0] }}%</span
                >
                {{ $t('offer.score') }} {{ participantsScore[1] }})
              </div>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>

      <v-card-text class="deal-title">“{{ offer.name }}”</v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-text v-if="offer.description" class="deal-description">{{
        description
      }}</v-card-text>

      <v-card-text
        v-if="continueReading"
        @click="continueReading = false"
        class="continue-reading pointer py-0"
      >
        {{ $t('incomingOffer.continue') }}
        <v-icon color="#9c6eff">mdi-chevron-right</v-icon>
      </v-card-text>

      <v-list-item>
        <v-row>
          <v-col cols="2" class="text-center pb-0">
            <v-icon size="24" color="#712FFF">mdi-clock-outline</v-icon>
          </v-col>
          <v-col cols="9" class="px-0 pb-0">
            <v-list-item-content class="deal-condition pt-0">
              <div v-if="isDraft">{{ $t('offer.saved') }} {{ createdAgo }}</div>
              <div v-else>{{ $t('offer.sent') }} {{ createdAgo }}</div>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>

      <v-list-item v-if="offer.dueDate">
        <v-row>
          <v-col cols="2" class="text-center py-0">
            <v-icon size="24" color="#712FFF">mdi-timer</v-icon>
          </v-col>
          <v-col cols="10" class="px-0 py-0">
            <v-list-item-content class="deal-condition pt-0">
              <div>
                {{ $t('offer.limited') }}
                <div class="purple-text inline">{{ dueDate }}</div>
              </div>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>

      <v-list-item v-if="offer.amount > 0">
        <v-row>
          <v-col cols="2" class="text-center py-0">
            <v-icon size="24" color="#712FFF">mdi-currency-usd</v-icon>
          </v-col>
          <v-col cols="10" class="px-0 py-0">
            <v-list-item-content class="deal-condition pt-0">
              <div>{{ offer.amount }} {{ offer.currency }}</div>
              <div>
                <div class="bold inline">
                  {{ $t('incomingOffer.paidBy') }} {{ payer }}
                </div>
                {{ $t('incomingOffer.to') }} {{ recipient }}
                {{
                  offer.payedViaGetRealLab
                    ? '-' + $t('incomingOffer.automaticPayment')
                    : ''
                }}
                <div v-if="offer.payedViaGetRealLab">
                  {{ $t('newOfferModal.includingFee') }}
                  {{ offer.amountWithFee }}
                  {{ offer.currency }}
                </div>
              </div>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>

      <v-divider v-if="offer.attachments.length" class="mx-4"></v-divider>

      <v-list-item v-if="offer.attachments.length">
        <v-row>
          <v-col cols="2" class="text-center pb-0">
            <v-icon size="24" color="#712FFF">mdi-paperclip</v-icon>
          </v-col>
          <v-col cols="9" class="px-0 pb-0">
            <v-list-item-content class="deal-condition pt-0">
              <div class="bold">{{ $t('incomingOffer.attachedFiles') }}</div>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item v-if="offer.attachments.length">
        <v-row>
          <v-col
            v-for="(preview, i) in offer.attachments"
            :key="i"
            cols="4"
            class="text-right px-0 py-2"
          >
            <v-img
              v-if="isImage(preview.type)"
              :src="preview.thumbnailUrl"
              @click="open(preview.url)"
              height="76"
              width="82"
            >
              <v-row align="end" class="lightbox white--text fill-height">
                <v-col class="filename py-2 px-1">
                  <div class="body-1">{{ fileName(preview.key) }}</div>
                </v-col>
              </v-row>
            </v-img>
            <v-img
              v-else
              @click="open(preview.url)"
              src="/file.png"
              height="76"
              width="82"
            >
              <v-row align="end" class="lightbox white--text fill-height">
                <v-col class="filename py-2 px-1">
                  <div class="body-1">{{ fileName(preview.key) }}</div>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-list-item>
    </div>
    <v-card-actions v-if="!isAccepted" class="pt-5">
      <v-btn @click="close()" v-if="review" text color="deep-purple accent-4">{{
        $t('back')
      }}</v-btn>
      <v-btn
        @click="deleteOffer"
        v-if="!review"
        text
        color="deep-purple accent-4"
        >{{ $t('delete') }}</v-btn
      >
      <v-btn
        v-if="isDraft"
        @click="dublicateOffer"
        text
        color="deep-purple accent-4"
        >{{ $t('duplicate') }}</v-btn
      >
      <v-btn
        v-if="isDraft"
        @click="showEditModal = true"
        text
        color="deep-purple accent-4"
        >{{ $t('edit') }}</v-btn
      >
      <div v-if="review" class="flex-grow-1"></div>
      <v-btn v-if="review" @click="send" text color="deep-purple accent-4">
        {{ $t('send') }}
      </v-btn>
    </v-card-actions>
    <NewOfferModal
      v-if="showEditModal"
      v-model="showEditModal"
      :offer="offer"
    />
  </v-card>
</template>

<script>
import IconArrow from '@/components/icons/IconArrow.vue'
import NewOfferModal from '@/components/modals/NewOfferModal.vue'

export default {
  name: 'OwnOfferModal',
  components: {
    IconArrow,
    NewOfferModal
  },
  props: {
    offer: Object,
    review: Boolean
  },
  data() {
    return {
      continueReading: true,
      showEditModal: false
    }
  },
  computed: {
    isAccepted() {
      return this.offer.status === 'accepted'
    },
    isAnonymous() {
      return this.offer.type === 'Anonymous'
    },
    isDraft() {
      return this.offer.status === 'draft'
    },
    participants() {
      const party = this.offer.parties.filter(
        (party) => party.partyRole === 'participant'
      )

      return party
    },
    createdAgo() {
      return this.$moment(this.offer.createdAt).fromNow()
    },
    dueDate() {
      const now = this.$moment.utc()
      const dueDate = this.$moment(this.offer.dueDate)
      const diff = dueDate.diff(now)
      const duration = this.$moment.duration(diff)
      const dueDateFormat =
        Math.floor(duration.asHours()) +
        this.$t('h') +
        this.$moment.utc(diff).format(':mm') +
        this.$t('m')

      return dueDateFormat
    },
    participantsScore() {
      if (this.participants.length === 1) {
        return [
          this.participants[0].user.reputationAsParticipant,
          this.participants[0].user.participantOfferAccepted
        ]
      } else {
        return [
          this.participants[0].user.reputationAsParticipant,
          this.participants[1].user.reputationAsParticipant
        ]
      }
    },
    needFeedback() {
      if (
        this.offer.parties.length === 2 &&
        this.offer.parties[0].status === 'accepted' &&
        this.offer.parties[1].status === 'accepted'
      ) {
        return true
      }
      return false
    },
    description() {
      if (this.offer.description.length > 200 && this.continueReading) {
        return this.offer.description.substring(0, 197) + '...'
      }

      return this.offer.description
    },
    timeLeft() {
      const party = this.offer.parties.find(
        (party) => party.user.username === this.$auth.user.username
      )

      if (party.feedbackDueDate) {
        return this.duration(party.feedbackDueDate, 'upcoming')
      }

      return false
    },
    payer() {
      return this.paymentParty(true)
    },
    recipient() {
      if (this.participants.length === 1) {
        return this.paymentParty(false)
      } else {
        const payer = this.offer.parties.find(function(party) {
          return party.payer === false && party.partyRole === 'participant'
        })

        return payer.user.username
      }
    },
    initiator() {
      const party = this.offer.parties.find(
        (party) => party.partyRole === 'initiator'
      )

      return party.user
    }
  },
  mounted() {
    if (this.offer.description.length <= 200) {
      this.continueReading = false
    }
  },
  methods: {
    async deleteOffer() {
      const confirm = await this.$root.$confirm.open(
        this.$t('delete'),
        this.$t('confirm.areYouSure'),
        { color: 'red' }
      )

      if (confirm) {
        const request = await this.$offerService.deleteOffer(this.offer.id)
        if (request.status === 200) {
          this.$toast.success(this.$t('toaster.offerDeleted'))
          this.$bus.$emit('offer-deleted', this.offer.id)
          this.$emit('hide')
        }
      }
    },
    async dublicateOffer() {
      try {
        const request = await this.$offerService.duplicateOffer(this.offer.id)
        this.$toast.success(this.$t('toaster.draftDublicated'))
        this.$bus.$emit('draft-saved', request.data.id)
      } catch (e) {
        this.error = e.response.data.errorMessage
      }
    },
    open(url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    fileName(fullName) {
      fullName = fullName.substr(20)
      let name = fullName

      if (fullName.length > 13) {
        const type = fullName.slice(-3)
        name = name.substring(0, 8) + '....' + type
      }

      return name
    },
    isImage(type) {
      return /(jpe?g|png|gif)$/i.test(type)
    },
    color(rating) {
      let color = ''

      if (rating < 50) {
        color = 'red-text'
      } else if (rating < 75) {
        color = 'yellow-text'
      } else {
        color = 'green-text'
      }

      return color
    },
    async sendFeedback(feedback) {
      const confirm = await this.$root.$confirm.open(
        this.$t('confirm.feedback' + feedback),
        this.$t('confirm.areYouSure')
      )

      if (confirm) {
        await this.$offerService.sendFeedback(this.offer.id, feedback)
        this.$bus.$emit('offer-deleted', this.offer.id)
        this.$toast.success(this.$t('toaster.feedbackSent'))
        this.$emit('hide')
      }
    },
    duration(date, type) {
      const now = this.$moment()
      const accepted = this.$moment(date)
      let diff = null
      if (type === 'upcoming') {
        diff = accepted.diff(now)
      } else {
        diff = now.diff(accepted)
      }

      const duration = this.$moment.duration(diff)
      const dueDateFormat =
        Math.floor(duration.asHours()) +
        this.$t('h') +
        this.$moment.utc(diff).format(':mm') +
        this.$t('m')

      return dueDateFormat
    },
    paymentParty(isPayer) {
      const payer = this.offer.parties.find((party) => party.payer === isPayer)
      if (payer.user.username === this.$auth.user.username) {
        return 'you'
      }

      return payer.user.username
    },
    close() {
      this.$emit('hide')
    },
    send() {
      this.$bus.$emit('send-offer')
      this.$emit('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 2px 25px rgba(0, 0, 0, 0.2),
    0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0px 0px;

  width: 303px;
}

.card-header {
  background: #712fff;
}

.rounded {
  border-radius: 8px 8px 0px 0px;
}

.green-text {
  color: #00ff75;
}

.yellow-text {
  color: #fdbe00;
}

.red-text {
  color: #ff4438;
}

.purple-text {
  color: #712fff;
}

hr {
  border-color: #dccdff !important;
}

.offer-type {
  align-self: flex-end;
  padding-bottom: 8px;
  padding-right: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
.card-title {
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
}

.card-header {
  height: 79px;
}

.card-header:after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #9c6eff;
  width: 100%;
  transform: translateX(-50%);
  bottom: -5px;
  left: 50%;
  z-index: 1;
}

.card-header.dummy:after {
  position: absolute;
  content: '';
  border-bottom: 2px dashed #fdbe00;
  width: 100%;
  transform: translateX(-50%);
  bottom: -5px;
  left: 50%;
  z-index: 1;
}

.card-sides {
  background: #f4f3f7;
}

.ampersand-spacer {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 20px;
}

.score {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.side-title {
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  overflow: inherit;
}

.deal-title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000 !important;
}

.deal-description {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000 !important;
}

.deal-condition > * {
  padding-top: 1px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px !important;
}
.oppacity {
  filter: opacity(60%);
}
.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  filter: none;
}
.bold {
  font-weight: bold;
}
.inline {
  display: inline;
}

.v-responsive.v-image {
  margin: auto;
  cursor: pointer;
}
.filename {
  background-color: #210759;
  opacity: 0.6;
  margin-left: 12px;
}

.filename .body-1 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px !important;
  line-height: 12px;
  float: left;
}
.upper-question {
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 8px;
}
.time-left {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #712fff;
}
.feedback {
  background: #dccdff;
}
.theme--light.v-list-item.action {
  background-color: #dccdff !important;

  button {
    height: 48px;
    color: #712fff;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
  }
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 8px 8px 0px 0px;
}

.continue-reading {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9c6eff !important;
}

.v-card__text {
  padding: 16px !important;
}
</style>
