<template>
  <v-dialog v-model="show" max-width="420px" ontent-class="mx-0" persistent>
    <v-card>
      <v-card-title class="headline">
        {{ $t('noMoreOpenOffersModal.title') }}
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="sendNoOpenNotification"
          :label="$t('noMoreOpenOffersModal.notifySender')"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="close" text>{{ $t('ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    offer: Object
  },
  data() {
    return {
      sendNoOpenNotification: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async close() {
      if (this.offer.presentAllowNotification && this.sendNoOpenNotification) {
        await this.$offerService.sendNoMoreOpenNotification(this.offer.id)
      }

      this.$emit('input', !this.value)
      this.$bus.$emit('offer-deleted', this.offer.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  background: white;
  color: #712fff;
  padding: 12px 0px 12px 16px !important;
  border-bottom: 1px solid #dccdff;
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 20px;
}
.v-card__text {
  padding: 20px 16px 5px 16px !important;
}
.v-btn {
  color: #712fff;
}
</style>
