import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _45b158f5 = () => interopDefault(import('..\\pages\\about.vue' /* webpackChunkName: "pages_about" */))
const _bd8877f0 = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages_contact" */))
const _31d51c7c = () => interopDefault(import('..\\pages\\dashboard.vue' /* webpackChunkName: "pages_dashboard" */))
const _462139ef = () => interopDefault(import('..\\pages\\deals.vue' /* webpackChunkName: "pages_deals" */))
const _2f10d6d1 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages_login" */))
const _0f306120 = () => interopDefault(import('..\\pages\\privacy.vue' /* webpackChunkName: "pages_privacy" */))
const _ad3985de = () => interopDefault(import('..\\pages\\profile.vue' /* webpackChunkName: "pages_profile" */))
const _5230f72a = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages_register" */))
const _84f3481c = () => interopDefault(import('..\\pages\\resetPassword.vue' /* webpackChunkName: "pages_resetPassword" */))
const _0f66f1ef = () => interopDefault(import('..\\pages\\terms.vue' /* webpackChunkName: "pages_terms" */))
const _931d2bbe = () => interopDefault(import('..\\pages\\verify\\index.vue' /* webpackChunkName: "pages_verify_index" */))
const _656933ba = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))
const _4c90fb60 = () => interopDefault(import('..\\pages\\verify\\_contact\\_code\\_id.vue' /* webpackChunkName: "pages_verify__contact__code__id" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _45b158f5,
    name: "about___en"
  }, {
    path: "/contact",
    component: _bd8877f0,
    name: "contact___en"
  }, {
    path: "/dashboard",
    component: _31d51c7c,
    name: "dashboard___en"
  }, {
    path: "/deals",
    component: _462139ef,
    name: "deals___en"
  }, {
    path: "/login",
    component: _2f10d6d1,
    name: "login___en"
  }, {
    path: "/privacy",
    component: _0f306120,
    name: "privacy___en"
  }, {
    path: "/profile",
    component: _ad3985de,
    name: "profile___en"
  }, {
    path: "/register",
    component: _5230f72a,
    name: "register___en"
  }, {
    path: "/resetPassword",
    component: _84f3481c,
    name: "resetPassword___en"
  }, {
    path: "/terms",
    component: _0f66f1ef,
    name: "terms___en"
  }, {
    path: "/verify",
    component: _931d2bbe,
    name: "verify___en"
  }, {
    path: "/he/",
    component: _656933ba,
    name: "index___he"
  }, {
    path: "/he/about",
    component: _45b158f5,
    name: "about___he"
  }, {
    path: "/he/contact",
    component: _bd8877f0,
    name: "contact___he"
  }, {
    path: "/he/dashboard",
    component: _31d51c7c,
    name: "dashboard___he"
  }, {
    path: "/he/deals",
    component: _462139ef,
    name: "deals___he"
  }, {
    path: "/he/login",
    component: _2f10d6d1,
    name: "login___he"
  }, {
    path: "/he/privacy",
    component: _0f306120,
    name: "privacy___he"
  }, {
    path: "/he/profile",
    component: _ad3985de,
    name: "profile___he"
  }, {
    path: "/he/register",
    component: _5230f72a,
    name: "register___he"
  }, {
    path: "/he/resetPassword",
    component: _84f3481c,
    name: "resetPassword___he"
  }, {
    path: "/he/terms",
    component: _0f66f1ef,
    name: "terms___he"
  }, {
    path: "/he/verify",
    component: _931d2bbe,
    name: "verify___he"
  }, {
    path: "/he/verify/:contact/:code?/:id?",
    component: _4c90fb60,
    name: "verify-contact-code-id___he"
  }, {
    path: "/verify/:contact/:code?/:id?",
    component: _4c90fb60,
    name: "verify-contact-code-id___en"
  }, {
    path: "/",
    component: _656933ba,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
