export default ($axios) => ({
  async getNotificationsCount() {
    const offers = await $axios.$get(
      'api/notifications/unreadNotificationsCount',
      { progress: false }
    )
    return offers
  },

  async getNotifications(credentials) {
    const params = credentials

    const notifications = await $axios.get('api/notifications', {
      params
    })
    return notifications
  }
})
