<template>
  <v-dialog
    v-model="show"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title :color="options.color" class="headline">
        <v-icon
          @click.native="cancel"
          size="23"
          color="#712fff"
          class="close-confirmation"
          >mdi-close</v-icon
        >
        {{ title }}
      </v-card-title>
      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="agree" class="v-btn" text>{{ $t('yes') }}</v-btn>
        <v-btn @click.native="cancel" class="v-btn" text>{{
          $t('cancel')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 200
    }
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__actions {
  padding: 12px;
}

.v-card__actions button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.v-card__title {
  background: white;
  color: #712fff;
  padding: 12px 0px 12px 16px !important;
  border-bottom: 1px solid #dccdff;
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 20px;
}
.close-confirmation {
  margin-right: 12px;
  cursor: pointer;
}
.v-card__text {
  padding: 20px 16px 5px 16px !important;
}
.v-btn {
  color: #712fff;
}
</style>
